import { Button } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import Countdown from 'react-countdown'
import { Link } from 'react-router-dom'
import { BookingCarDrawer } from '~/entities/BookingCar'
import { rentalContractModel } from '~/entities/RentalContract'
import { RentalContractStatusEnum } from '~/shared/config/enums'
import { formatMoscowToLocalDateTimeForUi } from '~/shared/lib/date'
import { useModal } from '~/shared/ui/Modal'
import {
  CloseWrapper,
  Descriptions,
  Phone,
  Title,
  Wrapper,
  Timer,
} from './styled'

export function Booking() {
  const { isModalShow, closeModal, openModal } = useModal()
  const rentalContract = useStore(rentalContractModel.$rentalContract)
  const cancellationFeeRub = rentalContract?.getCancellationFeeRub()

  const status = rentalContract?.getStatus()
  const isSigning = status === RentalContractStatusEnum.SIGNING

  const location = useStoreMap(
    rentalContractModel.$rentalContract,
    (rentalContract) => rentalContract?.getCar()?.getLocation(),
  )
  const bookedUntil = useStoreMap(
    rentalContractModel.$rentalContract,
    (rentalContract) => rentalContract?.getBookedUntil(),
  )

  const targetDate = bookedUntil
    ? formatMoscowToLocalDateTimeForUi(bookedUntil)
    : null

  const timer = useMemo(
    () =>
      !!targetDate && (
        <Countdown
          date={new Date(targetDate)}
          onComplete={rentalContractModel.getRentalContractSilent}
          renderer={({ days, hours, minutes }) => (
            <div>
              <span>{days ? `${days} д. ` : ''}</span>
              <span>{hours} ч.</span> <span>{minutes} мин.</span>
            </div>
          )}
        />
      ),
    [targetDate],
  )

  return (
    <>
      <Button
        variant='limeFlooded'
        size='large'
        fullWidth
        onClick={openModal}
        sx={{
          justifyContent: 'space-between',
          padding: '8px 8px 8px 16px !important',
          borderRadius: '12px',
        }}
      >
        {timer}
        <div>Бронь</div>
        <CloseWrapper>Отменить</CloseWrapper>
      </Button>

      <BookingCarDrawer
        isShow={isModalShow}
        onCancel={closeModal}
        location={location}
        cancellationFeeRub={cancellationFeeRub}
      >
        <Timer>Ещё &nbsp;{timer}</Timer>

        {isSigning && (
          <Button
            variant='limeFlooded'
            component={Link}
            size='large'
            fullWidth
            to='/rental-contract-signing'
            sx={{
              marginBottom: '16px',
            }}
          >
            Подписать
          </Button>
        )}

        <a style={{ textDecoration: 'none' }} href='tel:+79344770800'>
          <Wrapper>
            <Title>Отменить бронь по телефону</Title>
            <Phone>+7 (934) 477-08-00</Phone>
            <Descriptions>
              В Москве офис работает по будням 9:00–18:00 и в сб 10:00–16:00; в
              регионах — по будням 9:00–18:00, выходные — сб и вс.
            </Descriptions>
          </Wrapper>
        </a>
      </BookingCarDrawer>
    </>
  )
}
