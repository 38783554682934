import { styled } from '@mui/material'

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`

export const Label = styled('div')`
  font-size: 16px;
`
