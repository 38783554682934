import { ThumbType } from '~/shared/api'
import { GalleryImage } from './type'

export const mapPhotoToImage = <T extends string>(
  photo: ThumbType<T>,
): GalleryImage => ({
  id: photo.uuid,
  src: photo.originalUrlDownload,
  preview: photo.previewUrlDownload,
})
