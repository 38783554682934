import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const BookingPhoneWrapper = styled('a')`
  box-shadow: 0 0 5px ${myPalette.dark.shadowLight};
  background-color: ${myPalette.white.main};
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  text-decoration: none;
  padding: 16px;
  color: ${myPalette.black.main};
  cursor: pointer;
  margin-bottom: 8px;
`
export const BookingPhoneTitle = styled('div')`
  font-size: 18px;
  font-weight: 700;
`

export const BookingPhone = styled(BookingPhoneTitle)`
  font-weight: 400;
`

export const BookingPhoneDescriptions = styled('div')`
  font-size: 12px;
`
