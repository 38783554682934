import {
  parse as fnsParse,
  format,
  isValid,
  isBefore,
  addMonths,
  formatDuration as fnsFormatDuration,
  intervalToDuration,
  Interval,
  parseISO,
  subDays,
} from 'date-fns'
import { enUS } from 'date-fns/locale'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { datetime } from '~/shared/config'
import { uiDateFormat, uiDateTimeFormat } from '~/shared/config/datetime'
import { DayEnum } from '~/shared/config/enums'

export { addMonths, format as formatDate }

export const fromString = (input: string) =>
  fnsParse(input, datetime.apiDateFormat, new Date())

export const fromDatetimeString = (input: string) => parseISO(input)

export const isValidDateString = (input: string) => isValid(fromString(input))

export const formatForApi = (date: Date) => format(date, datetime.apiDateFormat)

export const formatDateTimeForUI = (input: string) =>
  format(fromDatetimeString(input), datetime.uiDateTimeFormat)

export const formatDateForUI = (input: string) =>
  format(fromDatetimeString(input), datetime.uiDateFormat)

export const formatTimeForUI = (input: string) =>
  format(fromDatetimeString(input), datetime.uiTimeFormat)

export const isBeforeNow = (date: Date | number) => isBefore(date, new Date())

export const formatDuration = (
  duration: Duration,
  options?: {
    format?: string[]
    zero?: boolean
    delimiter?: string
  },
) => fnsFormatDuration(duration, options)

export const formatIntervalAsDuration = (
  interval: Interval,
  options?: { format?: string[]; zero?: boolean; delimiter?: string },
) => {
  const duration = intervalToDuration(interval)
  return fnsFormatDuration(duration, options)
}

export const formatIntervalToNowAsDuration = (
  date: Date,
  options?: { format?: string[]; zero?: boolean; delimiter?: string },
) => {
  const now = new Date()
  return formatIntervalAsDuration({ start: date, end: now }, options)
}

export const formatMoscowDate = (utcDate: string) => {
  const moscowTimezone = 'Europe/Moscow'
  const moscowDateTime = utcToZonedTime(utcDate, moscowTimezone)

  return format(moscowDateTime, uiDateFormat)
}

export const formatMoscowDateTimeForUi = (utcDate: string | Date) => {
  const moscowTimezone = 'Europe/Moscow'
  const moscowDateTime = utcToZonedTime(utcDate, moscowTimezone)

  return format(moscowDateTime, uiDateTimeFormat)
}

export const formatMoscowToLocalDateTimeForUi = (moscowDate: string | Date) => {
  const moscowTimezone = 'Europe/Moscow'
  const utcDate = zonedTimeToUtc(moscowDate, moscowTimezone)
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const localDateTime = utcToZonedTime(utcDate, userTimezone)
  return format(localDateTime, 'yyyy-MM-dd HH:mm:ss')
}

export const formatMoscowTime = (utcTime: string) => {
  const moscowTimezone = 'Europe/Moscow'
  const utcDateTime = new Date(`${new Date().toDateString()} ${utcTime} UTC`)
  const moscowDateTime = utcToZonedTime(utcDateTime, moscowTimezone)

  return format(moscowDateTime, 'HH:mm')
}

export const getDay = (day: 'current' | 'yesterday'): DayEnum => {
  const yesterday = subDays(new Date(), day === 'current' ? 0 : 1)
  const dayName = format(yesterday, 'EEEE', { locale: enUS })

  return DayEnum[dayName as keyof typeof DayEnum]
}
