import { useStoreMap } from 'effector-react'
import { inspectionModel } from '~/entities/Inspection'
import { InspectionPhotoTypeEnum } from '~/shared/config/enums'
import { Gallery, mapPhotoToImage } from '~/shared/ui/Gallery'
import { EmptyPhotoTitle, Title, TypeTitle, Wrapper } from './styled'

export function Photos() {
  const photos = useStoreMap(
    inspectionModel.$inspection,
    (inspections) => inspections?.getPhotos() || [],
  )

  const carPhotos = photos
    ?.filter((photo) => photo.type === InspectionPhotoTypeEnum.CAR)
    .map(mapPhotoToImage)

  const tariffsPhotos = photos
    ?.filter((photo) => photo.type === InspectionPhotoTypeEnum.TARIFFS)
    .map(mapPhotoToImage)

  const mileagePhotos = photos
    ?.filter((photo) => photo.type === InspectionPhotoTypeEnum.MILEAGE)
    .map(mapPhotoToImage)

  const lastTOPhotosPhotos = photos
    ?.filter((photo) => photo.type === InspectionPhotoTypeEnum.LAST_TO)
    .map(mapPhotoToImage)

  const emptyPhoto = <EmptyPhotoTitle>Фото отсутствует</EmptyPhotoTitle>

  return (
    <Wrapper>
      <Title>Фотографии</Title>

      <div>
        <TypeTitle>Круговые фото</TypeTitle>
        {carPhotos?.length ? <Gallery images={carPhotos} /> : emptyPhoto}
      </div>

      <div>
        <TypeTitle>Тарифная сетка</TypeTitle>
        {tariffsPhotos?.length ? (
          <Gallery images={tariffsPhotos} />
        ) : (
          emptyPhoto
        )}
      </div>

      <div>
        <TypeTitle>Пробег</TypeTitle>
        {mileagePhotos?.length ? (
          <Gallery images={mileagePhotos} />
        ) : (
          emptyPhoto
        )}
      </div>

      <div>
        <TypeTitle>Бирка ТО</TypeTitle>
        {lastTOPhotosPhotos?.length ? (
          <Gallery images={lastTOPhotosPhotos} />
        ) : (
          emptyPhoto
        )}
      </div>
    </Wrapper>
  )
}
