import { Box, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { SuccessIcon, ErrorIcon, CancelIcon } from '~/shared/ui/icons'
import { Modal } from '~/shared/ui/Modal'
import { $notificationModal, notificationModalClose } from './model'

export function NotificationModal() {
  const config = useStore($notificationModal)

  return (
    <Modal
      isShow={Boolean(config)}
      onOk={notificationModalClose}
      ok={config?.buttonTitle}
    >
      <Box sx={{ mb: '20px', textAlign: 'center' }}>
        {config?.variant === 'success' && <SuccessIcon />}
        {config?.variant === 'error' && <ErrorIcon />}
        {config?.variant === 'warning' && <CancelIcon />}
      </Box>

      <Box sx={{ textAlign: 'center', mb: '40px' }}>
        {config && 'title' in config && (
          <Typography fontWeight={700} fontSize={22}>
            {config.title}
          </Typography>
        )}
        {config && 'description' in config && (
          <Typography mt='8px' fontSize={16} lineHeight='24px'>
            {config.description}
          </Typography>
        )}
      </Box>
    </Modal>
  )
}
