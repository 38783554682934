import { Option } from '~/shared/config/constants'
import { SelectMultiple } from '../SelectMultiple'
import { Wrapper } from './styled'

type SelectPops<T> = {
  field: string
  label: string
  modalTitle: string
  options: T[]
  gropedByKey?: string
}

type DependentSelectsProps<T> = {
  title: string
  first: SelectPops<T>
  second: SelectPops<T>
  isLoading?: boolean
}

export function DependentSelects<T extends Option>({
  title,
  first,
  second,
  isLoading,
}: DependentSelectsProps<T>) {
  return (
    <Wrapper>
      <SelectMultiple
        field={first.field}
        label={first.label}
        title={title}
        modalTitle={first.modalTitle}
        options={first.options}
        isLoading={isLoading}
        gropedByKey={first.gropedByKey}
      />

      <SelectMultiple
        field={second.field}
        label={second.label}
        modalTitle={second.modalTitle}
        options={second.options}
        isLoading={isLoading}
        gropedByKey={second.gropedByKey}
      />
    </Wrapper>
  )
}
