import { ThumbType } from '~/shared/api'
import { Gallery, mapPhotoToImage } from '~/shared/ui/Gallery'
import { Label, Wrapper, Text } from './styled'

type DamageProps = {
  detailTitle: string
  extentTitle: string
  cost: number
  isDetailLabelVisible?: boolean
  photos: ThumbType[]
}

export function Damage({
  detailTitle,
  extentTitle,
  cost,
  isDetailLabelVisible,
  photos,
}: DamageProps) {
  const images = photos.map(mapPhotoToImage)
  return (
    <Wrapper>
      <div>
        {isDetailLabelVisible && <Label>Деталь</Label>}
        <Text style={{ fontSize: isDetailLabelVisible ? '16px' : '18px' }}>
          {detailTitle}
        </Text>
      </div>
      <div>
        <Label>Степень повреждения</Label>
        <Text>{extentTitle}</Text>
      </div>
      <div>
        <Label>Стоимость ремонта</Label>
        <Text>{cost.toLocaleString()} руб.</Text>
      </div>
      <div>
        <Label>Фото</Label>
        <Gallery images={images} />
      </div>
    </Wrapper>
  )
}
