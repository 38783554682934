import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Card = styled('div')`
  box-shadow: 0 0 5px ${myPalette.dark.shadowLight};
  background-color: ${myPalette.white.main};
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  text-decoration: none;
  color: ${myPalette.black.main};
  padding: 16px 12px;
`

export const TitleWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`
