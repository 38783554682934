import { ReactNode, useMemo } from 'react'
import {
  CancelSmallIcon,
  ErrorSmallIcon,
  SuccessSmallIcon,
} from '~/shared/ui/icons'
import { myPalette } from '~/shared/ui/theme'
import { CheckTypesEnum } from '../enum'
import { Wrapper, Label, IconWrapper } from './styled'

type ItemProps = {
  type: CheckTypesEnum
  children: ReactNode
}

export function CheckItem({ type, children }: ItemProps) {
  const icon = useMemo(() => {
    switch (type) {
      case CheckTypesEnum.success:
        return <SuccessSmallIcon />
      case CheckTypesEnum.error:
        return <ErrorSmallIcon />
      default:
        return <CancelSmallIcon />
    }
  }, [type])

  const labelProps = useMemo(() => {
    if (type === CheckTypesEnum.error) {
      return { color: myPalette.coral.main }
    }
  }, [type])

  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>

      <Label sx={labelProps}>{children}</Label>
    </Wrapper>
  )
}
