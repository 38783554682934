import {
  carVehicleCategoriesOptions,
  transmissionEnumOptions,
} from '~/shared/config/constants'
import { FuelTypeEnum } from '~/shared/config/enums'
import { Filter } from '~/shared/ui/ScrollableList/Filters/types'

export const MAIN_REGIONS_OPTIONS_QUERY_KEY = 'main-regions-options'

export const sortConfig = [
  {
    key: '',
    label: 'По умолчанию',
  },
  {
    key: 'pricePerDay',
    label: 'По возрастанию цены',
  },
  {
    key: '-pricePerDay',
    label: 'По убыванию цены',
  },
  {
    key: '-manufactureYear',
    label: 'Сначала новые авто',
  },
]

export const filterElement = {
  region: {
    field: 'regionId',
    label: 'Регион, город',
    title: 'Где искать',
    modalTitle: 'Выбор региона',
  },
  location: {
    field: 'locationIds',
    label: 'Локация',
    modalTitle: 'Выбор локации',
  },
  brandModel: {
    title: 'Марка и модель',
    first: {
      field: 'brand',
      label: 'Марка',
      modalTitle: 'Выбор марки',
    },
    second: {
      field: 'model',
      label: 'Модель',
      modalTitle: 'Выбор модели',
      gropedByKey: 'gropedByKey',
    },
  },
  yearn: {
    title: 'Год выпуска',
    option: {
      fromField: 'manufactureYearGte',
      toField: 'manufactureYearLte',
      fromLabel: 'От',
      toLabel: 'До',
    },
  },
  price: {
    title: 'Стоимость аренды в сутки',
    option: {
      fromField: 'pricePerDayGte',
      toField: 'pricePerDayLte',
      fromLabel: 'От',
      toLabel: 'До',
    },
  },
  transmission: {
    title: 'Тип КПП',
    field: 'transmission',
    options: transmissionEnumOptions,
  },
  fuelType: {
    title: 'Вид топлива',
    field: 'fuelType',
    options: [
      {
        id: [
          FuelTypeEnum.AI92,
          FuelTypeEnum.AI95,
          FuelTypeEnum.AI98,
          FuelTypeEnum.AI100,
        ].join(','),
        label: 'Бензин',
      },
      { id: FuelTypeEnum.PROPANE, label: 'Пропан' },
      { id: FuelTypeEnum.METHANE, label: 'Метан' },
      { id: FuelTypeEnum.DIESEL, label: 'ДТ' },
      { id: FuelTypeEnum.ELECTRICITY, label: 'Электричество' },
    ],
  },
  vehicleCategory: {
    title: 'Класс авто',
    field: 'vehicleCategory',
    options: carVehicleCategoriesOptions,
  },
}

export const filtersConfig: Filter[] = [
  {
    type: 'autocomplete',
    excludeClear: true,
    ...filterElement.region,
  },
  {
    type: 'selectMultiple',
    ...filterElement.location,
  },
  {
    type: 'autocompleteMultipleDependent',
    ...filterElement.brandModel,
  },
  {
    type: 'range',
    ...filterElement.price,
  },
  {
    type: 'yearRange',
    ...filterElement.yearn,
  },
  {
    type: 'multiButtons',
    ...filterElement.transmission,
  },
  {
    type: 'multiButtons',
    ...filterElement.fuelType,
  },
  {
    type: 'multiButtons',
    ...filterElement.vehicleCategory,
  },
]
