import { Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useFormContext } from 'react-hook-form'
import { InputWrapper, Title, IonInput, IonRange } from './styled'

type NumberRangeProps = {
  title: string
  fetchOptions: () => Promise<{ min: string | null; max: string | null }>
  queryKey?: string
  option: {
    fromField: string
    toField: string
    fromLabel: string
    toLabel: string
  }
}

export function NumberRange({
  title,
  fetchOptions,
  queryKey,
  option: { fromField, toField, fromLabel, toLabel },
}: NumberRangeProps) {
  const { setValue, watch } = useFormContext()

  const fromValue = watch(fromField)
  const toValue = watch(toField)

  const { isFetching, data } = useQuery(
    ['async-number-range-options', fromField, toField, queryKey],
    () => fetchOptions(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  const isMin = data?.min || data?.min === '0'
  const isMax = data?.max || data?.max === '0'

  const dataMin = isMin ? Math.floor(Number(data.min) / 100) * 100 : undefined
  const dataMax = isMax ? Math.ceil(Number(data.max) / 100) * 100 : undefined

  const min = dataMin || 0
  const max = dataMax || 100

  const enabled = !isFetching && isMin && isMax && min !== max

  return (
    <div>
      <Title>{title}</Title>

      {isFetching && (
        <>
          <Skeleton
            variant='rectangular'
            height={26}
            width='100%'
            sx={{ borderRadius: '6px', marginBottom: '14px' }}
          />
          <Skeleton
            variant='rectangular'
            height={48}
            width='100%'
            sx={{ borderRadius: '6px' }}
          />
        </>
      )}

      {!isFetching && (
        <>
          <IonRange
            disabled={!enabled}
            dualKnobs
            pin
            step={100}
            min={min}
            max={max}
            value={{
              lower: fromValue || min,
              upper: toValue || max,
            }}
            onIonChange={(e) => {
              const { lower, upper } = e.detail.value as {
                lower: number
                upper: number
              }
              setValue(fromField, lower, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              })
              setValue(toField, upper, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              })
            }}
          />

          <InputWrapper>
            <IonInput
              value={fromValue}
              label={`${fromLabel} ${dataMin || '-'}`}
              name={fromField}
              mode='md'
              readonly
              labelPlacement='floating'
              fill='outline'
            />
            <IonInput
              value={toValue}
              label={`${toLabel} ${dataMax || '-'}`}
              name={toField}
              mode='md'
              readonly
              labelPlacement='floating'
              fill='outline'
            />
          </InputWrapper>
        </>
      )}
    </div>
  )
}
