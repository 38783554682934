import { Skeleton, Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { balanceHistoriesModel } from '~/entities/BalanceHistories'
import { CURRENCY } from '~/shared/config/constants'
import { MoveFundsType } from '~/shared/config/enums'
import { ButtonOptionWrapper } from './styled'

const getTypographyFontSize = (amount: number) => {
  if (amount >= 100000) return 18
  if (amount >= 10000) return 20
  return 22
}

export const useFilters = () => {
  const isLoading = useStore(
    balanceHistoriesModel.getBalanceReportForDayFx.pending,
  )

  const balanceReport = useStore(balanceHistoriesModel.$balanceReportForDay)
  const comingAmount = balanceReport?.getComingAmount() || 0
  const spendingAmount = balanceReport?.getSpendingAmount() || 0

  return [
    {
      key: MoveFundsType.COMING,
      template: (
        <ButtonOptionWrapper>
          <Typography fontSize={14} color='mutedText.main'>
            Приход
          </Typography>
          <Typography
            fontSize={getTypographyFontSize(comingAmount)}
            fontWeight={700}
            data-testid='button-groups-coming-amount'
          >
            {isLoading ? (
              <Skeleton />
            ) : (
              `${comingAmount.toLocaleString()} ${CURRENCY}`
            )}
          </Typography>
        </ButtonOptionWrapper>
      ),
    },
    {
      key: MoveFundsType.SPENDING,
      template: (
        <ButtonOptionWrapper>
          <Typography fontSize={14} color='mutedText.main'>
            Расход
          </Typography>
          <Typography
            fontSize={getTypographyFontSize(spendingAmount)}
            fontWeight={700}
            data-testid='button-groups-spending-amount'
          >
            {isLoading ? (
              <Skeleton />
            ) : (
              `${spendingAmount.toLocaleString()} ${CURRENCY}`
            )}
          </Typography>
        </ButtonOptionWrapper>
      ),
    },
  ]
}
