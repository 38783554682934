import { Box, Typography } from '@mui/material'

import { useStore } from 'effector-react'
import { TinkoffPay } from '~/widgets/TinkoffPay'
import { payModel } from '~/entities/Balance'
import { CURRENCY } from '~/shared/config/constants'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { DetailAmountTitle, DetailBlock, DetailItemTitle } from './styled'

type ConfirmPayDrawerProps = {
  isShow: boolean
  onClose: () => void
}

const getSum = (value: unknown) =>
  typeof value === 'number' ? value.toLocaleString() : '-'

export function ConfirmPayDrawer({ isShow, onClose }: ConfirmPayDrawerProps) {
  const commission = useStore(payModel.$payCommission)
  const amount = commission?.amount

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Box sx={{ textAlign: 'center', marginBottom: '44px' }}>
        <Typography fontSize={12}>Итоговая сумма начисления</Typography>
        <Typography
          fontSize={32}
          fontWeight={500}
          data-testid='confirm-pay-amount-total'
        >
          {getSum(commission?.amountTotal)} {CURRENCY}
        </Typography>
      </Box>

      <Typography fontSize={16} mb='16px'>
        Детали:
      </Typography>
      <DetailBlock marginBottom='8px'>
        <DetailItemTitle>Сумма с комиссией</DetailItemTitle>
        <DetailAmountTitle data-testid='confirm-pay-amount'>
          {getSum(amount)} {CURRENCY}
        </DetailAmountTitle>
      </DetailBlock>
      <DetailBlock marginBottom='44px'>
        <DetailItemTitle>Комиссия</DetailItemTitle>
        <DetailAmountTitle data-testid='confirm-pay-commission-amount'>
          {getSum(commission?.commissionAmount)} {CURRENCY}
        </DetailAmountTitle>
      </DetailBlock>

      {amount ? (
        <TinkoffPay amount={amount} />
      ) : (
        <Box>Укажите сумму пополнения</Box>
      )}
    </DrawerComponent>
  )
}
