import { Unstable_Grid2 as Grid } from '@mui/material'
import { useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import { Img } from '~/shared/ui/Img'
import { GridItem } from './styled'
import { GalleryImage } from './type'

import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

type ImageSliderProps = {
  images: GalleryImage[]
  xs?: number
}

export function Gallery({ images, xs = 3 }: ImageSliderProps) {
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const [sliders, setSliders] = useState<
    (GalleryImage & { loading: boolean })[]
  >(images.map((image) => ({ ...image, loading: true })))

  const changeSelectedIndex = (index: number) => setSelectedIndex(index)

  const isOpen = selectedIndex >= 0

  const handleImgOnLoad = (objectURL: string, id: UniqueId) => {
    setSliders((prevSliders) =>
      prevSliders.map((prevSlider) =>
        prevSlider.id === id
          ? {
              ...prevSlider,
              src: objectURL,
              preview: objectURL,
              loading: false,
            }
          : prevSlider,
      ),
    )
  }

  return (
    <>
      <Grid container spacing={1.5}>
        {images.map((item, index) => (
          <GridItem
            xs={xs}
            key={item.id}
            onClick={() => changeSelectedIndex(index)}
          >
            <Img
              onLoad={(objectURL) => handleImgOnLoad(objectURL, item.id)}
              url={item.src}
            />
          </GridItem>
        ))}
      </Grid>

      <Lightbox
        styles={{
          root: {
            backgroundColor: 'rgba(17, 20, 40, 0.8)',
            backdropFilter: 'blur(40px)',
          },
          toolbar: {
            paddingTop: 'max(var(--ion-safe-area-top, 0px), 8px)',
          },
          container: { backgroundColor: 'transparent' },
          thumbnailsContainer: {
            paddingBottom: 'max(var(--ion-safe-area-bottom, 0px), 16px)',
          },
        }}
        carousel={{
          finite: images.length === 1,
        }}
        slides={sliders}
        open={isOpen}
        index={selectedIndex}
        close={() => changeSelectedIndex(-1)}
        plugins={[Zoom, Thumbnails]}
        animation={{ zoom: 300 }}
        zoom={{ maxZoomPixelRatio: 10, scrollToZoom: true }}
        thumbnails={{
          padding: 0,
          width: 72,
          height: 72,
          border: 0,
          borderRadius: 12,
          vignette: false,
        }}
      />
    </>
  )
}
