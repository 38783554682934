import {
  ListBookingCars,
  RegionsFilter,
  SelectedTaxipark,
} from '~/features/BookingCars/ListBookingCars'
import { PageContainer } from '~/shared/ui/PageContainer'

export function ListBookingCarsPage() {
  return (
    <PageContainer
      title='Аренда авто'
      backTitle='Аренда авто'
      canBack
      preventScrollReset
      topContent={
        <div>
          <div style={{ marginBottom: '24px' }}>
            <RegionsFilter />
          </div>

          <SelectedTaxipark />
        </div>
      }
    >
      <ListBookingCars />
    </PageContainer>
  )
}
