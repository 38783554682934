import { LoadingButton as MuiLoadingButton } from '@mui/lab'
import { styled, Skeleton as MuiSkeleton, Typography } from '@mui/material'

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
`

export const ButtonSigningWrapper = styled(ButtonWrapper)`
  justify-content: space-between;
`

export const WaitingSigningTitle = styled(Typography)<{ chipColor?: string }>(
  ({ chipColor }) => `
  margin-left: 15px;
  position: relative;

  &:before {
    content: '';
    width: 11px;
    height: 11px;
    background: ${chipColor};
    position: absolute;
    bottom: 6px;
    left: -15px;
    border-radius: 10px;
  }
`,
)

export const Skeleton = styled(MuiSkeleton)`
  height: 36px;
  width: 100%;
  border-radius: 6px;
`

export const LoadingButton = styled(MuiLoadingButton)`
  padding: 4px 8px;
  font-size: 14px;
`
