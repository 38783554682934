import { Title } from '../Title'
import { Label } from './styled'

export type AddressProps = {
  name?: string
}

export function Address({ name }: AddressProps) {
  return (
    <div>
      <Title>Адрес</Title>

      <Label>{name ? name : 'Адрес не найден! Уточните у оператора'}</Label>
    </div>
  )
}
