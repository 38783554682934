export function CancelSmallIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='16' height='16' rx='8' fill='#B2BDD6' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9877 5.58015C11.025 5.5429 11.0546 5.49867 11.0748 5.44998C11.095 5.4013 11.1054 5.34911 11.1055 5.2964C11.1055 5.24369 11.0952 5.19149 11.075 5.14278C11.0549 5.09407 11.0253 5.04981 10.9881 5.01251C10.9508 4.97522 10.9066 4.94563 10.8579 4.92543C10.8092 4.90522 10.7571 4.89481 10.7043 4.89478C10.6516 4.89475 10.5994 4.9051 10.5507 4.92524C10.502 4.94538 10.4578 4.97492 10.4205 5.01216L7.99995 7.43272L5.58013 5.01216C5.50482 4.93684 5.40266 4.89453 5.29615 4.89453C5.18963 4.89453 5.08748 4.93684 5.01216 5.01216C4.93684 5.08748 4.89453 5.18964 4.89453 5.29616C4.89453 5.40267 4.93684 5.50483 5.01216 5.58015L7.43267 8L5.01216 10.4199C4.97487 10.4571 4.94529 10.5014 4.9251 10.5501C4.90492 10.5989 4.89453 10.6511 4.89453 10.7038C4.89453 10.7566 4.90492 10.8088 4.9251 10.8575C4.94529 10.9063 4.97487 10.9505 5.01216 10.9878C5.08748 11.0632 5.18963 11.1055 5.29615 11.1055C5.34889 11.1055 5.40111 11.0951 5.44984 11.0749C5.49857 11.0547 5.54284 11.0251 5.58013 10.9878L7.99995 8.56728L10.4205 10.9878C10.4958 11.0631 10.5979 11.1053 10.7043 11.1052C10.8108 11.1052 10.9129 11.0628 10.9881 10.9875C11.0633 10.9122 11.1055 10.81 11.1055 10.7036C11.1054 10.5971 11.0631 10.4951 10.9877 10.4199L8.56722 8L10.9877 5.58015Z'
        fill='white'
      />
    </svg>
  )
}
