import { AxiosHeaders } from 'axios'
import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { http } from '~/shared/api'

const domain = createDomain('pages.protectedBookingCarsPage')
export const Gate = createGate<{ xTaxiparkKey: UniqueId }>()

const setHttpHeaderFx = domain.createEffect<
  { xTaxiparkKey: UniqueId },
  { interceptorId: number }
>({
  handler: ({ xTaxiparkKey }) => {
    const interceptorId = http.axiosInstance.interceptors.request.use(
      (config) => {
        config.headers = AxiosHeaders.from({
          ...config.headers,
          'X-TAXIPARK-KEY': xTaxiparkKey,
        })
        return config
      },
    )
    return { interceptorId }
  },
})

export const $interceptorId = domain
  .createStore<{ interceptorId: number } | null>(null)
  .on(setHttpHeaderFx.doneData, (_, interceptorId) => interceptorId)

const ejectHttpHeaderFx = domain.createEffect<{ interceptorId: number }, void>({
  handler: ({ interceptorId }) => {
    http.axiosInstance.interceptors.request.eject(interceptorId)
  },
})

sample({
  clock: Gate.open,
  target: setHttpHeaderFx,
})

sample({
  clock: Gate.close,
  source: $interceptorId,
  filter: (interceptorId) => !!interceptorId,
  target: ejectHttpHeaderFx,
})
