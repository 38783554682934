import { CircularProgress } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ImgHTMLAttributes, useEffect } from 'react'
import { Media } from '~/shared/api'
import { Wrapper } from './styled'

type ImgProps = {
  url: string
  onLoad?: (objectURL: string) => void
} & Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'onLoad'>

export function Img({ url, onLoad, ...props }: ImgProps) {
  const queryKey = ['image', url]

  const queryClient = useQueryClient()
  const cachedObjectURL = queryClient.getQueryData<string>(queryKey)

  const fetchMedia = async () => {
    const blob = await Media.getMedia(url)

    return URL.createObjectURL(blob)
  }

  const { data: objectURL, isLoading } = useQuery<string>(
    queryKey,
    () => fetchMedia(),
    {
      enabled: !cachedObjectURL,
      retry: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (objectURL) onLoad?.(objectURL)
    // eslint-disable-next-line
  }, [objectURL])

  if (isLoading && !objectURL) {
    return (
      <Wrapper>
        <CircularProgress
          color='limeGreen'
          sx={{ display: 'block', width: '24px', height: '24px' }}
        />
      </Wrapper>
    )
  }

  return <img src={objectURL || ''} alt='Изоброжение' {...props} />
}
