import { ReactNode } from 'react'
import {
  Content,
  IonAccordion,
  IonBadge,
  IonItem,
  Title,
  TitleWrapper,
} from './styled'

type AccordionProps = {
  children: ReactNode
  title: ReactNode | string
  count?: number
  value: string
}

export function Accordion({ children, title, count, value }: AccordionProps) {
  return (
    <IonAccordion value={value}>
      <IonItem slot='header'>
        <TitleWrapper>
          <Title>{title}</Title>
          {!!count && <IonBadge>{count}</IonBadge>}
        </TitleWrapper>
      </IonItem>

      <Content slot='content'>{children}</Content>
    </IonAccordion>
  )
}
