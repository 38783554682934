import { Title } from '~/entities/BookingCar'
import { BookingCar } from '~/shared/api'
import {
  fuelTypeEnumLabels,
  transmissionEnumLabels,
} from '~/shared/config/constants'
import { mileageFormat } from '~/shared/lib/utils'
import { Wrapper, Label, Value, Row, Dots } from './styled'

export type CharacteristicsProps = {
  bookingCar: BookingCar
}

export function Characteristics({ bookingCar }: CharacteristicsProps) {
  const carAttr = bookingCar.getAttributes()
  const rows = [
    { label: 'Цвет кузова', value: carAttr.color },
    { label: 'Тип КПП', value: transmissionEnumLabels[carAttr.transmission] },
    { label: 'Вид топлива', value: fuelTypeEnumLabels[carAttr.fuelType] },
    { label: 'Категория ТС', value: carAttr.vehicleCategory },
    { label: 'Год выпуска', value: carAttr.manufactureYear },
    carAttr.mileage
      ? { label: 'Пробег', value: `${mileageFormat(carAttr.mileage)} км` }
      : null,
  ].filter((row) => !!row)

  return (
    <div>
      <Title>Характеристики</Title>

      <Wrapper>
        {rows.map(({ label, value }) => (
          <Row key={label}>
            <Label>{label}</Label>
            <Dots />
            <Value>{value}</Value>
          </Row>
        ))}
      </Wrapper>
    </div>
  )
}
