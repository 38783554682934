import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import { BookingCar } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const domain = createDomain('entities.bookingCar')

export const requestFx = domain.createEffect<UniqueId, BookingCar>({
  handler: fetchBookingCar,
})

const {
  $cache: $bookingCarsCache,
  useCache: useBookingCarCache,
  updateCache,
} = createCache<BookingCar>({
  domain,
  getEntityId: (bookingCar) => bookingCar.getApiId() as UniqueId,
})
export { $bookingCarsCache, useBookingCarCache }

$bookingCarsCache.on(requestFx.doneData, (cache, bookingCar) =>
  updateCache(cache, [bookingCar]),
)

export const $bookingCarsError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(requestFx.fail, (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useBookingCarError = (id: UniqueId) =>
  useStoreMap($bookingCarsError, (errors) => errors[id])

async function fetchBookingCar(id: UniqueId) {
  const res = await BookingCar.with('location').find(id)

  return res.getData() as BookingCar
}
