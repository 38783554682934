import { Typography } from '@mui/material'
import { Damage } from '~/entities/Inspection'
import { InspectionDamageAttributes } from '~/shared/api'
import { DrawerComponent } from '~/shared/ui/Drawer'

type DamageDrawerProps = {
  isShow: boolean
  onClose: () => void
  damage?: InspectionDamageAttributes
}

export function DamageDrawer({ isShow, onClose, damage }: DamageDrawerProps) {
  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      {damage ? (
        <Damage
          detailTitle={damage.detailTitle}
          extentTitle={damage.extentTitle}
          cost={damage.cost}
          photos={damage.photos}
        />
      ) : (
        <Typography fontSize='18'>Повреждение не найдено</Typography>
      )}
    </DrawerComponent>
  )
}
