import { useStoreMap } from 'effector-react'
import { useMemo, useState } from 'react'
import { inspectionModel } from '~/entities/Inspection'
import { InspectionDamageAttributes } from '~/shared/api'
import { CarDetailTypeEnum } from '~/shared/config/enums'
import { PolygonMarkers } from '~/shared/ui/Polygon'
import carBodySvg from '~/shared/ui/Polygon/Images/carBody.svg'
import carEnvironmentSvg from '~/shared/ui/Polygon/Images/carEnvironment.svg'
import carInteriorSvg from '~/shared/ui/Polygon/Images/carInterior.svg'
import { PolygonMarker } from '~/shared/ui/Polygon/types'
import { mapDamagesToMarkers } from './mappers'
import { Wrapper } from './styled'
import { DamageDrawer } from './ui/DamageDrawer'
import { Legend } from './ui/Legend'

export function DamagesInspection() {
  const damages =
    useStoreMap(inspectionModel.$inspection, (inspection) =>
      inspection?.getDamages(),
    ) || []

  const damagesExterior = damages.filter(
    ({ detailType }) => detailType === CarDetailTypeEnum.EXTERIOR,
  )
  const damagesTire = damages.filter(
    ({ detailType }) => detailType === CarDetailTypeEnum.TIRE,
  )
  const damagesInterior = damages.filter(
    ({ detailType }) => detailType === CarDetailTypeEnum.INTERIOR,
  )
  const damagesEnvironment = damages.filter(
    ({ detailType }) => detailType === CarDetailTypeEnum.ENVIRONMENT,
  )

  const markersBody = useMemo(
    () => [
      ...mapDamagesToMarkers(damagesExterior),
      ...mapDamagesToMarkers(damagesTire),
    ],
    [damagesExterior, damagesTire],
  )

  const markersInterior = useMemo(
    () => [...mapDamagesToMarkers(damagesInterior)],
    [damagesInterior],
  )

  const markersEnvironment = useMemo(
    () => [...mapDamagesToMarkers(damagesEnvironment)],
    [damagesEnvironment],
  )

  const [selectedDamage, setSelectedDamage] =
    useState<InspectionDamageAttributes>()

  const handleSelectedMarker = (marker: PolygonMarker) => {
    const foundMarker = damages.find(({ detailId }) => detailId === marker.id)
    setSelectedDamage(foundMarker)
  }

  const handleDamageModalClose = () => {
    setSelectedDamage(undefined)
  }

  return (
    <Wrapper>
      <PolygonMarkers
        img={carBodySvg}
        onMarkerSelect={handleSelectedMarker}
        markers={markersBody}
      />

      <PolygonMarkers
        img={carInteriorSvg}
        onMarkerSelect={handleSelectedMarker}
        markers={markersInterior}
      />

      <PolygonMarkers
        img={carEnvironmentSvg}
        onMarkerSelect={handleSelectedMarker}
        markers={markersEnvironment}
      />

      <Legend />

      <DamageDrawer
        isShow={Boolean(selectedDamage)}
        onClose={handleDamageModalClose}
        damage={selectedDamage}
      />
    </Wrapper>
  )
}
