type RubIconProps = {
  background?: string
}

export function RubIcon({ background = 'white' }: RubIconProps) {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='21' height='21' rx='10.5' fill={background} />
      <path
        d='M10.852 4.672C11.492 4.672 12.0787 4.73067 12.612 4.848C13.1453 4.95467 13.604 5.14667 13.988 5.424C14.372 5.69067 14.6707 6.05867 14.884 6.528C15.0973 6.98667 15.204 7.568 15.204 8.272C15.204 8.944 15.076 9.52533 14.82 10.016C14.564 10.496 14.2227 10.8853 13.796 11.184C13.4227 11.4507 12.9907 11.648 12.5 11.776C12.02 11.904 11.4813 11.968 10.884 11.968H8.884V13.152H11.46V14.832H8.884V16H6.564V14.832H5.252V13.152H6.564V11.968H5.252V10.096H6.564V4.672H10.852ZM10.724 10.096C11.0227 10.096 11.3 10.0693 11.556 10.016C11.8227 9.952 12.052 9.856 12.244 9.728C12.4467 9.58933 12.6067 9.41333 12.724 9.2C12.8413 8.98667 12.9053 8.72 12.916 8.4C12.9267 8.03733 12.8733 7.74933 12.756 7.536C12.6493 7.312 12.4947 7.14133 12.292 7.024C12.1 6.90667 11.8653 6.832 11.588 6.8C11.3107 6.75733 11.0067 6.736 10.676 6.736H8.884V10.096H10.724Z'
        fill='#021542'
      />
    </svg>
  )
}
