import { LoadingButton } from '@mui/lab'
import { Skeleton } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo, useState } from 'react'
import { driverModel } from '~/entities/Driver'
import { formatPhoneNumber } from '~/shared/lib/utils'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { VerificationFieldElement } from '~/shared/ui/form'
import { RepeatCode } from '~/shared/ui/RepeatCode'
import {
  TIMER_REPEAT_RENTAL_CONTRACT_SIGNING,
  TIMER_REPEAT_RENTAL_CONTRACT_SIGNING_TIME,
} from '../constants'

import { Description, Title, Wrapper } from './styled'

type ConfirmDrawerProps = {
  isShow: boolean
  onOk: (code: string) => void
  onCancel: () => void
  onCodeRepeat?: () => void
  isSubmitting: boolean
}

export function ConfirmDrawer({
  isShow,
  onOk,
  onCancel,
  onCodeRepeat,
  isSubmitting,
}: ConfirmDrawerProps) {
  const phone = useStoreMap(driverModel.$driver, (profile) =>
    profile?.getPhone(),
  )
  const driverPending = useStore(driverModel.getDriverFx.pending)

  const [code, setCode] = useState<string>()

  const handleCodeChange = (code: string) => setCode(code)

  const handleOnOk = () => {
    onOk(code as string)
  }

  const phoneExtra = useMemo(
    () =>
      driverPending ? (
        <Skeleton variant='rounded' height={27} width='100%' />
      ) : (
        formatPhoneNumber(phone)
      ),

    [driverPending, phone],
  )

  return (
    <DrawerComponent isShow={isShow} onCancel={onCancel}>
      <Wrapper>
        <Title>Подтверждение по СМС</Title>

        <Description>
          Введите код, который мы отправили на ваш номер <br />
          {phoneExtra}
        </Description>

        <VerificationFieldElement onChange={handleCodeChange} />
        <br />

        <RepeatCode
          onCodeRepeat={onCodeRepeat}
          time={TIMER_REPEAT_RENTAL_CONTRACT_SIGNING_TIME}
          storageKey={TIMER_REPEAT_RENTAL_CONTRACT_SIGNING}
        />

        <br />

        <LoadingButton
          onClick={handleOnOk}
          loading={isSubmitting}
          fullWidth
          variant='limeFlooded'
          size='large'
          disabled={code?.length !== 4}
        >
          Подтвердить
        </LoadingButton>
      </Wrapper>
    </DrawerComponent>
  )
}
