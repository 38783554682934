import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const Wrapper = styled('div')`
  padding: 16px 20px;
  border-radius: 12px;
  background: ${myPalette.limeGreen.main};
  position: relative;

  &:before {
    content: '';
    width: 11px;
    height: 11px;
    background: ${myPalette.coral.main};
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 10px;
  }
`

export const Title = styled('div')`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 4px;
`

export const Description = styled('div')`
  font-size: 16px;
  margin-bottom: 8px;
`
