import { IonBadge as IonicIonBadge } from '@ionic/react'
import { styled } from '@mui/material'
import { Link } from 'react-router-dom'
import { myPalette } from '~/shared/ui/theme'
import { WhiteBlock } from '~/shared/ui/Wrappers'

export const LinkWrapper = styled(Link)`
  text-decoration: none;
`

export const Wrapper = styled(WhiteBlock)`
  padding: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled('div')`
  font-size: 16px;
  font-weight: 500;
  color: ${myPalette.midnightBlue.main};
  margin-bottom: 4px;
`

export const Descriptions = styled('div')`
  font-size: 14px;
  color: ${myPalette.dark.main};
  display: flex;
  align-items: center;
`

export const IonBadge = styled(IonicIonBadge)`
  width: 11px;
  height: 11px;
  padding: 0;
  border-radius: 100%;
  display: block;
  margin-right: 6px;
`
