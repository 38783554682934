import { useGate, useStore } from 'effector-react'
import { useEffect } from 'react'
import { useQueryParams, StringParam } from 'use-query-params'
import { ConfirmPayDrawer } from '~/features/Balance/ConfirmPayDrawer'
import { ConfirmWithdrawalDrawer } from '~/features/Balance/ConfirmWithdrawalDrawer'
import { ListBalance } from '~/features/Balance/ListBalance'
import { PayDrawer } from '~/features/Balance/PayDrawer'
import { WithdrawalDrawer } from '~/features/Balance/WithdrawalDrawer'
import {
  BalanceHeading,
  withdrawalModel,
  payDrawerModel,
  balanceModel,
  confirmWithdrawalModel,
  confirmPayModel,
} from '~/entities/Balance'
import { CurrentBalance } from '~/entities/MainBalance'
import { pushFx, replaceFx } from '~/shared/lib/history'
import { notificationModalOpen } from '~/shared/lib/notificationModal'
import { PageContainer } from '~/shared/ui/PageContainer'

export function ListBalancePage() {
  useGate(balanceModel.Gate)

  const [query] = useQueryParams({
    tinkoffPay: StringParam,
    Message: StringParam,
  })
  const { tinkoffPay, Message } = query

  const isWithdrawalDrawerOpen = useStore(
    withdrawalModel.$isWithdrawalDrawerOpen,
  )
  const isConfirmWithdrawalDrawerOpen = useStore(
    confirmWithdrawalModel.$isConfirmWithdrawalDrawerOpen,
  )
  const isPayDrawerOpen = useStore(payDrawerModel.$isPayDrawerOpen)
  const isConfirmPayDrawerOpen = useStore(
    confirmPayModel.$isConfirmPayDrawerOpen,
  )

  useEffect(() => {
    if (tinkoffPay === 'success') {
      notificationModalOpen({
        title: 'Успешное пополнение баланса',
        description:
          'Ваш баланс успешно пополнен. Средства в обработке и будут доступны в ближайшее время.',
        variant: 'success' as const,
      })
    }
    if (tinkoffPay === 'error') {
      notificationModalOpen({
        title: 'Ошибка при пополнении баланса',
        description:
          Message ||
          'К сожалению, возникла ошибка при попытке пополнения баланса. Пожалуйста, попробуйте еще раз позже.',
        variant: 'error' as const,
      })
    }
    if (tinkoffPay) replaceFx('/balance')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tinkoffPay])

  return (
    <PageContainer
      canBack
      onBack={() => pushFx('/')}
      title='Баланс'
      topContent={
        <BalanceHeading
          onPayout={withdrawalModel.openWithdrawalDrawer}
          onPay={payDrawerModel.openPayDrawer}
        />
      }
      extraRight={<CurrentBalance disabled />}
    >
      <ListBalance />

      <WithdrawalDrawer
        isShow={isWithdrawalDrawerOpen}
        onClose={withdrawalModel.closeWithdrawalDrawer}
      />
      <ConfirmWithdrawalDrawer
        isShow={isConfirmWithdrawalDrawerOpen}
        onClose={confirmWithdrawalModel.closeConfirmWithdrawalDrawer}
      />

      <PayDrawer
        isShow={isPayDrawerOpen}
        onClose={payDrawerModel.closePayDrawer}
      />
      <ConfirmPayDrawer
        isShow={isConfirmPayDrawerOpen}
        onClose={confirmPayModel.closeConfirmPayDrawer}
      />
    </PageContainer>
  )
}
