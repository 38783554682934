export function ClockIcon() {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.1696 4.08464V8.1696L10.8929 9.53125M14.9779 8.1696C14.9779 11.9297 11.9297 14.9779 8.1696 14.9779C4.40949 14.9779 1.36133 11.9297 1.36133 8.1696C1.36133 4.40949 4.40949 1.36133 8.1696 1.36133C11.9297 1.36133 14.9779 4.40949 14.9779 8.1696Z'
        stroke='#B2BDD6'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
