import { Skeleton, Unstable_Grid2 as Grid } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { mainBalance } from '~/entities/MainBalance'
import { CURRENCY } from '~/shared/config/constants'
import { Wrapper, NavText, TitleWrapper, Img, Title } from './styled'

export function BalanceNav() {
  const isBalanceReportLoading = useStore(
    mainBalance.getCurrentBalanceReportFx.pending,
  )

  const profitAmount = useStoreMap(
    mainBalance.$currentBalanceReport,
    (balanceReport) => balanceReport?.getProfitAmount() || 0,
  )
  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Wrapper to='/balance-histories' data-testid='button-balance-histories'>
          <TitleWrapper>
            <Img src='/images/icon-wallet.svg' alt='' />
            <Title>Заработок сегодня</Title>
          </TitleWrapper>

          <NavText data-testid='balance-histories-profit-amount'>
            {isBalanceReportLoading ? (
              <Skeleton />
            ) : (
              `${profitAmount.toLocaleString()} ${CURRENCY}`
            )}
          </NavText>
        </Wrapper>
      </Grid>
      {/*<Grid xs={5}>*/}
      {/*  <Wrapper to='/profile/debt'>*/}
      {/*    <TitleWrapper>*/}
      {/*      <Img src='/images/icon-close-circle.svg' alt='' />*/}
      {/*      <Title>Долг</Title>*/}
      {/*    </TitleWrapper>*/}
      {/*    <NavText>0 {CURRENCY}</NavText>*/}
      {/*  </Wrapper>*/}
      {/*</Grid>*/}
    </Grid>
  )
}
