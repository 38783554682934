import { Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Taxipark } from '~/shared/api'
import { Title, SubTitle, Wrapper } from './styled'

export function SelectedTaxipark() {
  const {
    isFetching,
    isLoading,
    isPreviousData,
    data: currentTaxipark,
  } = useQuery<Taxipark>(['get-current-taxipark'], () => Taxipark.current(), {
    keepPreviousData: true,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const name = currentTaxipark?.getName()

  const title = useMemo(() => {
    if (isLoading || (isFetching && isPreviousData)) {
      return <Skeleton variant='rounded' height={24} width='100%' />
    }

    return name
  }, [isFetching, isLoading, isPreviousData, name])

  return (
    <Wrapper>
      <SubTitle>Активный таксопарк</SubTitle>
      <Title data-testid='selected-taxipark'>{title}</Title>
    </Wrapper>
  )
}
