import { Skeleton } from '~/shared/ui/SkeletonList'

export function LoadingBlock() {
  return (
    <>
      <Skeleton
        animated
        $height={400}
        style={{ marginBottom: '44px', marginTop: 0 }}
      />
      <Skeleton
        animated
        $height={400}
        style={{ marginBottom: '44px', marginTop: 0 }}
      />
      <Skeleton
        animated
        $height={400}
        style={{ marginBottom: '44px', marginTop: 0 }}
      />
    </>
  )
}
