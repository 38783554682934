import { useQuery } from '@tanstack/react-query'
import { filterElement } from '~/features/BookingCars/ListBookingCars/config'
import { Region } from '~/shared/api'
import { SelectMultiple } from '~/shared/ui/ScrollableList'

const { location } = filterElement

type LocationsProps = {
  regionId?: UniqueId
  onChange: () => void
}

export function Locations({ regionId, onChange }: LocationsProps) {
  const handleLocationsGet = async () => {
    if (!regionId) return []

    const response = await Region.with('locations').find(regionId)
    return response
      ?.getData()
      ?.getLocations()
      .map((location) => location?.getOption())
  }

  const { isFetching, data = [] } = useQuery(
    ['async-location-options', regionId],
    () => handleLocationsGet(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  return (
    <SelectMultiple
      {...location}
      isLoading={isFetching}
      options={data}
      onChange={onChange}
    />
  )
}
