import { styled } from '@mui/material'

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
`

export const Label = styled('div')`
  font-size: 16px;
  font-weight: 500;
`

export const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 12px;
`
