import { Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { rentalContractModel } from '~/entities/RentalContract'
import { formatDateForUI } from '~/shared/lib/date'
import { replaceFx } from '~/shared/lib/history'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { useDrawer } from '~/shared/ui/Drawer'
import { ArrowRightIcon } from '~/shared/ui/icons'
import { useStorageRepeatCode } from '~/shared/ui/RepeatCode'
import { ConfirmDrawer } from './ConfirmDrawer'
import {
  TIMER_REPEAT_RENTAL_CONTRACT_SIGNING,
  TIMER_REPEAT_RENTAL_CONTRACT_SIGNING_TIME,
} from './constants'
import {
  loadRentalContractFileFx,
  loadRentalContractFile,
  confirmCode,
  confirmCodeFx,
  confirm,
  confirmFx,
} from './model'
import { FileButton, SignButton, Wrapper, DescriptionWrapper } from './styled'

export function RentalContractSigning() {
  const rentalContract = useStore(rentalContractModel.$rentalContract)

  const createdAd = rentalContract?.getCreatedAt()

  const [isDrawerShow, openDrawer, closeDrawer] = useDrawer()
  const { runTimer, getIsTimerWorking } = useStorageRepeatCode(
    TIMER_REPEAT_RENTAL_CONTRACT_SIGNING,
  )
  const confirmCodePending = useStore(confirmCodeFx.pending)
  const confirmPending = useStore(confirmFx.pending)
  const isRentalContractFilePending = useStore(loadRentalContractFileFx.pending)

  const warningRequestCod = () => {
    snackbarEnqueued({
      message:
        'Запросите код повторно после того как пройдет блокировка получения кода',
      variant: 'warning',
    })
  }

  const handleCodeRequest = () => {
    if (getIsTimerWorking()) {
      warningRequestCod()
      openDrawer()
    } else {
      confirmCode({
        fn: () => {
          runTimer(TIMER_REPEAT_RENTAL_CONTRACT_SIGNING_TIME)
          openDrawer()
        },
      })
    }
  }

  const handleConfirm = (code: string) => {
    confirm({
      code,
      fn: () => {
        closeDrawer()
        replaceFx('/')
      },
    })
  }

  return (
    <>
      <Wrapper>
        <DescriptionWrapper>
          <Typography fontSize={16} fontWeight={600} lineHeight='18px' mb='6px'>
            Договор <br />
            оказания услуг
          </Typography>

          {createdAd && (
            <Typography fontSize={14} color='mutedText'>
              От {formatDateForUI(createdAd)}
            </Typography>
          )}
        </DescriptionWrapper>
        <FileButton
          variant='limeFlooded'
          startIcon={!isRentalContractFilePending && <ArrowRightIcon />}
          onClick={() => loadRentalContractFile()}
          loading={isRentalContractFilePending}
        >
          Просмотреть
        </FileButton>
      </Wrapper>

      <SignButton
        size='large'
        variant='limeFlooded'
        fullWidth
        onClick={handleCodeRequest}
        loading={confirmCodePending}
      >
        Подписать
      </SignButton>

      <ConfirmDrawer
        onOk={handleConfirm}
        isShow={isDrawerShow}
        onCancel={closeDrawer}
        isSubmitting={confirmPending}
        onCodeRepeat={handleCodeRequest}
      />
    </>
  )
}
