import * as z from 'zod'
import { Location } from '~/shared/api'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel, ToManyRelation } from './core'

const attributeSchema = z.object({
  name: z.string().trim().min(1, 'Обязательное поле'),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type RegionAttributes = z.infer<typeof attributeSchema>

export class Region extends ApiModel<typeof schema, RegionAttributes> {
  static jsonApiType = 'regions'

  static schema = schema

  getName(): string {
    return this.getAttribute('name')
  }

  locations(): ToManyRelation<Location, this> {
    return this.hasMany(Location)
  }
  getLocations(): Location[] {
    return this.getRelation('locations')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }

  static async fetchOptions() {
    const response = await Region.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
