import { InspectionDamageAttributes } from '~/shared/api'
import { CarDamageExtendsCodeEnum } from '~/shared/config/enums'
import { PolygonMarker } from '~/shared/ui/Polygon/types'

const getLevel = (extentCode?: CarDamageExtendsCodeEnum) => {
  switch (extentCode) {
    case CarDamageExtendsCodeEnum.LIGHT:
      return 'Л'
    case CarDamageExtendsCodeEnum.MEDIUM:
      return 'С'
    case CarDamageExtendsCodeEnum.HEAVY:
      return 'Т'
    default:
      return undefined
  }
}

export const mapDamagesToMarkers = (
  damages?: InspectionDamageAttributes[],
): PolygonMarker[] => {
  if (!damages) return []

  return damages.map((damage) => ({
    level: getLevel(damage.extentCode),
    left: damage.left,
    top: damage.top,
    id: damage.detailId,
  }))
}
