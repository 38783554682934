import { LoadingButton } from '@mui/lab'
import { useIsFetching } from '@tanstack/react-query'
import { useMemo } from 'react'
import {
  filtersConfig,
  MAIN_REGIONS_OPTIONS_QUERY_KEY,
} from '~/features/BookingCars/ListBookingCars/config'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'
import { FiltersActiveIcon, FiltersIcon } from '~/shared/ui/icons'
import {
  sanitizeFilterFields,
  useFiltersQueryParams,
} from '~/shared/ui/ScrollableList'
import { myPalette } from '~/shared/ui/theme'
import { Content } from './Content'

export function Filters() {
  const [isShow, openDrawer, closeDrawer] = useDrawer()
  const mainRegionsOptionsIsFetching = useIsFetching([
    MAIN_REGIONS_OPTIONS_QUERY_KEY,
  ])

  const [filtersQuery] = useFiltersQueryParams(filtersConfig)

  const isFiltersSelected = useMemo(() => {
    const fields = sanitizeFilterFields(filtersConfig)
    return fields.some((field) => !!filtersQuery[field])
  }, [filtersQuery])

  return (
    <div>
      <LoadingButton
        fullWidth
        loading={!!mainRegionsOptionsIsFetching}
        startIcon={isFiltersSelected ? <FiltersActiveIcon /> : <FiltersIcon />}
        variant='text'
        onClick={openDrawer}
        sx={{
          padding: 0,
          color: myPalette.black.main,
        }}
      >
        Фильтры
      </LoadingButton>

      <DrawerComponent isShow={isShow} onCancel={closeDrawer}>
        <Content onClose={closeDrawer} />
      </DrawerComponent>
    </div>
  )
}
