import { useGate, useStoreMap } from 'effector-react'
import { useParams } from 'react-router-dom'
import {
  DamagesInspection,
  LoadingBlock,
} from '~/features/Inspections/DamagesInspection'
import { inspectionModel } from '~/entities/Inspection'
import { PageContainer } from '~/shared/ui/PageContainer'

export function DamagesInspectionsPage() {
  const params = useParams<{ inspectionId: UniqueId }>()
  const paramsInspectionId = params.inspectionId as UniqueId

  useGate(inspectionModel.Gate, { id: paramsInspectionId })

  const inspectionId = useStoreMap(inspectionModel.$inspection, (inspection) =>
    inspection?.getApiId(),
  )
  const inspectionLoaded = inspectionId === paramsInspectionId

  return (
    <PageContainer
      title='Карта повреждений'
      backTitle='Карта повреждений'
      canBack
    >
      {inspectionLoaded ? <DamagesInspection /> : <LoadingBlock />}
    </PageContainer>
  )
}
