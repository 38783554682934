import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { balanceModel } from '~/entities/Balance'
import { $selectedPayCard, payout, payoutFx } from '~/entities/Balance/model'
import { CURRENCY } from '~/shared/config/constants'
import { DrawerComponent } from '~/shared/ui/Drawer'
import { PayCardContent, PayCardWrapper } from '~/shared/ui/PayCard'
import {
  DetailAmountTitle,
  DetailBlock,
  DetailItemTitle,
  Title,
} from './styled'

type ConfirmWithdrawalDrawerProps = {
  isShow: boolean
  onClose: () => void
}

const getSum = (value: unknown) =>
  typeof value === 'number' ? value.toLocaleString() : '-'

export function ConfirmWithdrawalDrawer({
  isShow,
  onClose,
}: ConfirmWithdrawalDrawerProps) {
  const selectedPayCard = useStoreMap($selectedPayCard, (payCard) =>
    payCard?.getAttributes(),
  )
  const commission = useStore(balanceModel.$commission)

  const isSubmitting = useStore(payoutFx.pending)

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Box sx={{ textAlign: 'center', marginBottom: '44px' }}>
        <Typography fontSize={12}>Итоговая сумма для вывода</Typography>
        <Typography
          fontSize={32}
          fontWeight={500}
          data-testid='confirm-withdrawal-amount-total'
        >
          {getSum(commission?.amountTotal)} {CURRENCY}
        </Typography>
      </Box>

      <Typography fontSize={16} mb='16px'>
        Детали:
      </Typography>
      <DetailBlock marginBottom='8px'>
        <DetailItemTitle>Сумма без комиссии</DetailItemTitle>
        <DetailAmountTitle data-testid='confirm-withdrawal-amount'>
          {getSum(commission?.amount)} {CURRENCY}
        </DetailAmountTitle>
      </DetailBlock>
      <DetailBlock marginBottom='32px'>
        <DetailItemTitle>Комиссия</DetailItemTitle>
        <DetailAmountTitle data-testid='confirm-withdrawal-commission-amount'>
          {getSum(commission?.commissionAmount)} {CURRENCY}
        </DetailAmountTitle>
      </DetailBlock>

      <Title>Вывести на карту:</Title>
      <Box
        sx={{ marginBottom: '44px' }}
        data-testid='confirm-withdrawal-pay-card'
      >
        <PayCardWrapper>
          <PayCardContent
            cardNumber={selectedPayCard?.maskedNumber as string}
          />
        </PayCardWrapper>
      </Box>
      <LoadingButton
        fullWidth
        variant='limeFlooded'
        size='large'
        onClick={() => payout()}
        loading={isSubmitting}
      >
        Подтвердить
      </LoadingButton>
    </DrawerComponent>
  )
}
