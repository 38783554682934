import { Button } from '@mui/material'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useQueryParam, withDefault } from 'use-query-params'
import {
  CheckItemList,
  CheckTypesEnum,
  inspectionModel,
} from '~/entities/Inspection'
import { Accordion, AccordionGroup } from '~/shared/ui/AccordionGroup'
import {
  AccordionKeys,
  DelimitedAccordionArrayParam,
  getBody,
  getDocuments,
  getEquipments,
} from './helpers'
import { Damages } from './ui/Damages'
import { GoToPreviousInspection } from './ui/GoToPreviousInspection'
import { Header } from './ui/Header'
import { Photos } from './ui/Photos'
// import { Wheels } from './ui/Wheels'

export function ViewInspection() {
  const inspection = useStore(inspectionModel.$inspection)
  const previousInspection = useStore(inspectionModel.$previousInspection)

  const [accordionKeysQuery, setAccordionKeysQuery] = useQueryParam(
    'accordion_keys',
    withDefault(DelimitedAccordionArrayParam, []),
  )

  const handleAccordionChange = (keys: AccordionKeys[]) => {
    setAccordionKeysQuery(keys, 'replaceIn')
  }

  const damages = useStoreMap(inspectionModel.$inspection, (inspection) =>
    inspection?.getDamages(),
  )

  const body = useMemo(
    () => getBody(inspection, previousInspection),
    [inspection, previousInspection],
  )
  const bodyErrors = body.filter(
    ({ type }) => type === CheckTypesEnum.error,
  )?.length

  const documents = useMemo(
    () =>
      getDocuments(
        inspection?.getDocuments(),
        previousInspection?.getDocuments(),
      ),
    [inspection, previousInspection],
  )
  const documentsErrors = documents.filter(
    ({ type }) => type === CheckTypesEnum.error,
  )?.length

  const equipments = useMemo(
    () =>
      getEquipments(
        inspection?.getEquipments(),
        previousInspection?.getEquipments(),
      ),
    [inspection, previousInspection],
  )
  const equipmentsErrors = equipments.filter(
    ({ type }) => type === CheckTypesEnum.error,
  )?.length

  if (!inspection) return null

  return (
    <>
      <Header />

      <AccordionGroup<AccordionKeys>
        defaultValue={accordionKeysQuery}
        onChange={handleAccordionChange}
      >
        <Accordion
          title='Повреждения'
          value={AccordionKeys.damages}
          count={damages?.length}
        >
          <Button
            fullWidth
            variant='lightSteelBlueBorder'
            component={Link}
            size='large'
            to='damages'
            sx={{ marginBottom: '32px' }}
          >
            Карта повреждений
          </Button>

          <Damages damages={damages} />
        </Accordion>

        <Accordion title='Салон' count={bodyErrors} value={AccordionKeys.cabin}>
          <CheckItemList checkItems={body} />
        </Accordion>

        <Accordion
          title='Документы'
          count={documentsErrors}
          value={AccordionKeys.documents}
        >
          <CheckItemList checkItems={documents} />
        </Accordion>

        <Accordion
          title='Комплектность'
          count={equipmentsErrors}
          value={AccordionKeys.equipments}
        >
          <CheckItemList checkItems={equipments} />
        </Accordion>

        {/*<Accordion title='Колеса' value={AccordionKeys.wheels}>*/}
        {/*  <Wheels />*/}
        {/*</Accordion>*/}
      </AccordionGroup>

      <br />

      <Photos />

      <GoToPreviousInspection />
    </>
  )
}
