import { styled, Typography, Box } from '@mui/material'
import { myPalette } from '~/shared/ui/theme/myPalette'

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
`

export const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
`

export const Description = styled(Typography)`
  text-align: center;
  color: ${myPalette.mutedText.main};
  font-size: 16px;
  margin-bottom: 24px;
  font-weight: 400;
`
