import * as z from 'zod'
import { BookingCar } from '~/shared/api/bookingCar'
import { DayEnum, RentalContractWorkScheduleEnum } from '~/shared/config/enums'
import {
  dateSchema,
  enumOptionSchema,
  uuidOptionSchema,
} from '~/shared/lib/schemas'
import { ApiModel, ToOneRelation } from './core'

const attributeSchema = z.object({
  workSchedule: enumOptionSchema(RentalContractWorkScheduleEnum),
  dayOff: enumOptionSchema(DayEnum).nullable(),
})

const relationsSchema = z.object({
  bookingCarId: uuidOptionSchema,
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type RentalContactCreationRequestAttributes = z.infer<typeof schema>

export class RentalContactCreationRequest extends ApiModel<
  typeof schema,
  RentalContactCreationRequestAttributes
> {
  static jsonApiType = 'rental-contract-creation-requests'

  static schema = schema

  bookingCar(): ToOneRelation<BookingCar, this> {
    return this.hasOne(BookingCar)
  }
  getBookingCar(): BookingCar {
    return this.getRelation('bookingCar')
  }
  setBookingCar(id: UniqueId) {
    const bookingCar = new BookingCar()
    bookingCar.setApiId(id)
    this.setRelation('bookingCar', bookingCar)
  }
}
