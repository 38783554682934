import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'
import { WhiteBlock } from '~/shared/ui/Wrappers'

export const Title = styled('div')`
  margin-bottom: 12px;
  font-size: 18px;
`

export const ItemsList = styled(WhiteBlock)`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 23px;
  padding: 16px 20px;
  position: relative;
`

export const ItemWrapper = styled('div')`
  display: flex;
  align-items: center;
`

export const Degree = styled('div')`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${myPalette.dark['100']};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

export const Img = styled('img')`
  margin-right: 8px;
  width: 25px;
`

export const Circle = styled('div')<{ $background: string }>`
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 100%;
  margin-right: 6px;
  background: ${({ $background }) => $background};
`

export const IconWrapper = styled('div')`
  position: absolute;
  right: 16px;
  top: 16px;
`
