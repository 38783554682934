import { IonIcon } from '@ionic/react'
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons'
import { Controller } from 'react-hook-form'
import { Option } from '~/shared/config/constants'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'
import { IonCheckbox as Checkbox } from '../Checkbox'
import { DrawerWrapper, IonInput } from './styled'

type SelectProps = {
  options: (Option & { disabled?: boolean })[]
  name: string
  disabled?: boolean
  label: string
}

export function Select({ options, name, disabled, label }: SelectProps) {
  const [isShow, openDrawer, closeDrawer] = useDrawer()

  return (
    <Controller
      name={name}
      render={({ field: { onChange, value } }) => {
        const handleOnChange = (option: Option) => {
          closeDrawer()
          onChange(option)
        }

        return (
          <>
            <div onClick={disabled ? undefined : openDrawer}>
              <IonInput
                disabled={disabled}
                value={value?.label}
                mode='md'
                label={label}
                labelPlacement='floating'
                fill='outline'
              >
                <IonIcon
                  slot='end'
                  icon={isShow ? chevronUpOutline : chevronDownOutline}
                />
              </IonInput>
            </div>
            <DrawerComponent isShow={isShow} onCancel={closeDrawer}>
              <DrawerWrapper>
                {options.map((option) => (
                  <Checkbox
                    onClick={() => handleOnChange(option)}
                    key={option.id}
                    labelPlacement='end'
                    justify='start'
                    checked={option?.id === value?.id}
                    disabled={option?.disabled}
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </DrawerWrapper>
            </DrawerComponent>
          </>
        )
      }}
    />
  )
}
