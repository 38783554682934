import { IonIcon, IonLabel } from '@ionic/react'
import { bulb } from 'ionicons/icons'
import { IonChip } from './styled'

export function SignedWarning() {
  return (
    <IonChip color='warning'>
      <IonIcon icon={bulb}></IonIcon>
      <IonLabel>
        Договор подписан, списания начнутся после того как вы заберёте
        автомобиль с площадки
      </IonLabel>
    </IonChip>
  )
}
