import {
  FuelTypeEnum,
  TransmissionEnum,
  RentalStatusEnum,
  RentalContractStatusEnum,
  RentalContractWorkScheduleEnum,
  DayEnum,
  DocumentsTypeEnum,
} from '~/shared/config/enums'

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token'
export const AUTH_PHONE_STORAGE_KEY = 'auth_phone'
export const SELECTED_PAY_CARD_BANK_CARD_ID = 'SELECTED_PAY_CARD_BANK_CARD_ID'
export const CURRENCY = '₽'

export type Option = { id: UniqueId; label: string }
export type OptionEnum<T> = { id: T; label: string }

export const carVehicleCategoriesOptions: Option[] = [
  { id: 'Эконом', label: 'Эконом' },
  { id: 'Комфорт', label: 'Комфорт' },
  { id: 'Комфорт +', label: 'Комфорт +' },
]

export const transmissionEnumLabels: Record<TransmissionEnum, string> = {
  [TransmissionEnum.AUTO]: 'Автомат',
  [TransmissionEnum.MANUAL]: 'Механика',
}

export const transmissionEnumOptions: Option[] = Object.keys(
  TransmissionEnum,
).map((value) => {
  const label = transmissionEnumLabels[value as keyof typeof TransmissionEnum]
  return {
    label,
    id: value,
  }
})

export const fuelTypeEnumLabels: Record<FuelTypeEnum, string> = {
  [FuelTypeEnum.AI92]: 'Бензин',
  [FuelTypeEnum.AI95]: 'Бензин',
  [FuelTypeEnum.AI98]: 'Бензин',
  [FuelTypeEnum.AI100]: 'Бензин',
  [FuelTypeEnum.DIESEL]: 'ДТ',
  [FuelTypeEnum.PROPANE]: 'Пропан',
  [FuelTypeEnum.METHANE]: 'Метан',
  [FuelTypeEnum.ELECTRICITY]: 'Электричество',
}

export const fuelTypeEnumOptions: Option[] = Object.keys(FuelTypeEnum).map(
  (value) => {
    const label = fuelTypeEnumLabels[value as keyof typeof FuelTypeEnum]
    return {
      label,
      id: value,
    }
  },
)

export const rentalContractWorkScheduleEnumOptions: OptionEnum<RentalContractWorkScheduleEnum>[] =
  Object.keys(RentalContractWorkScheduleEnum).map((value) => {
    const label =
      RentalContractWorkScheduleEnum[
        value as keyof typeof RentalContractWorkScheduleEnum
      ]
    return {
      label,
      id: label,
    }
  })

export const rentalStatusEnumLabels: Record<RentalStatusEnum, string> = {
  [RentalStatusEnum.IN_PROGRESS]: 'На рассмотрении',
  [RentalStatusEnum.APPROVED]: 'Одобрено',
}

export const rentalContractStatusEnumLabels: Record<
  RentalContractStatusEnum,
  string
> = {
  [RentalContractStatusEnum.SUSPENDED]: 'Приостановлен',
  [RentalContractStatusEnum.ACTIVE]: 'Активный',
  [RentalContractStatusEnum.SIGNING]: 'На подписании',
  [RentalContractStatusEnum.SIGNED]: 'Подписан',
}

export const daysEnumLabels: Record<DayEnum, string> = {
  [DayEnum.Monday]: 'Понедельник',
  [DayEnum.Tuesday]: 'Вторник',
  [DayEnum.Wednesday]: 'Среда',
  [DayEnum.Thursday]: 'Четверг',
  [DayEnum.Friday]: 'Пятница',
  [DayEnum.Saturday]: 'Суббота',
  [DayEnum.Sunday]: 'Воскресенье',
}

export const daysEnumOptions: OptionEnum<DayEnum>[] = Object.keys(DayEnum).map(
  (value) => {
    const label = daysEnumLabels[value as keyof typeof DayEnum]
    return {
      label,
      id: value as DayEnum,
    }
  },
)

export const documentsCodeEnumLabels: Record<DocumentsTypeEnum, string> = {
  [DocumentsTypeEnum.OSAGO]: 'Осаго',
  [DocumentsTypeEnum.STS]: 'СТС',
  [DocumentsTypeEnum.DC]: 'Диагностическая карта',
  [DocumentsTypeEnum.LICENSE]: 'Лицензия',
}
