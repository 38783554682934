import { styled } from '@mui/material'

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Label = styled('div')`
  font-size: 14px;
  margin-bottom: 4px;
`

export const Text = styled('div')`
  font-weight: 500;
`
