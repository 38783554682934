import { useState } from 'react'
import { ListBalanceHistories } from '~/features/BalanceHistories/ListBalanceHistories'
import { BalanceCalendarHeading } from '~/entities/BalanceHistories'
import { formatForApi } from '~/shared/lib/date'
import { PageContainer } from '~/shared/ui/PageContainer'

export function ListBalanceHistoriesPage() {
  const [date, setDate] = useState<string>(formatForApi(new Date()))
  const handleDateChange = (date: string) => setDate(date)

  return (
    <PageContainer
      canBack
      title='История баланса за день'
      topContent={<BalanceCalendarHeading onDateChange={handleDateChange} />}
    >
      <ListBalanceHistories date={date} />
    </PageContainer>
  )
}
