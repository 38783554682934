import * as z from 'zod'
import {
  CarDamageExtendsCodeEnum,
  CarDetailTypeEnum,
  CarEquipmentTypeEnum,
  DocumentsTypeEnum,
  InspectionPhotoTypeEnum,
} from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  manufactureYear: z.string(),
  hasTobaccoSmell: z.boolean(),
  isDirtyEnterior: z.boolean(),
  isDryCleaningNeed: z.boolean(),
  mileage: z.number(),
  finishedAt: dateSchema,
  equipments: z.array(
    z.object({
      attributes: z.object({
        value: z.boolean(),
        type: z.nativeEnum(CarEquipmentTypeEnum),
        title: z.string(),
        equipmentId: z.string(),
      }),
    }),
  ),
  documents: z.array(
    z.object({
      attributes: z.object({
        value: z.boolean(),
        type: z.nativeEnum(DocumentsTypeEnum),
      }),
    }),
  ),
  violations: z.array(
    z.object({
      attributes: z.object({
        title: z.string(),
        cost: z.number(),
      }),
    }),
  ),
  damages: z.array(
    z.object({
      attributes: z.object({
        left: z.number(),
        top: z.number(),
        cost: z.number(),
        extentCode: z.nativeEnum(CarDamageExtendsCodeEnum),
        extentTitle: z.string(),
        detailType: z.nativeEnum(CarDetailTypeEnum),
        detailTitle: z.string(),
        detailId: z.string(),
        extentId: z.string(),
        photos: z.array(
          z.object({
            uuid: z.string(),
            previewUrlDownload: z.string(),
            originalUrlDownload: z.string(),
            type: z.string(),
          }),
        ),
      }),
    }),
  ),
  photos: z.array(
    z.object({
      uuid: z.string(),
      previewUrlDownload: z.string(),
      originalUrlDownload: z.string(),
      type: z.nativeEnum(InspectionPhotoTypeEnum),
    }),
  ),
})

const schema = z
  .object({
    createdAt: dateSchema,
  })
  .merge(attributesSchema)

export type InspectionAttributes = z.infer<typeof attributesSchema>
export type InspectionViolation = InspectionAttributes['violations'][0]
export type InspectionEquipment = InspectionAttributes['equipments'][0]
export type InspectionEquipmentAttributes = InspectionEquipment['attributes']
export type InspectionDocument = InspectionAttributes['documents'][0]
export type InspectionDocumentAttributes = InspectionDocument['attributes']
export type InspectionDamage = InspectionAttributes['damages'][0]
export type InspectionDamageAttributes = InspectionDamage['attributes']
export type InspectionPhotos = InspectionAttributes['photos'][0]

export class Inspection extends ApiModel<typeof schema, InspectionAttributes> {
  static jsonApiType = 'inspections'

  static schema = schema

  getFinishedAt(): string {
    return this.getAttribute('finishedAt')
  }
  getMileage(): number {
    return this.getAttribute('mileage')
  }
  getHasTobaccoSmell(): boolean {
    return this.getAttribute('hasTobaccoSmell')
  }
  getIsDirtyEnterior(): boolean {
    return this.getAttribute('isDirtyEnterior')
  }
  getIsDryCleaningNeed(): boolean {
    return this.getAttribute('isDryCleaningNeed')
  }

  getPhotos(): InspectionPhotos[] {
    return this.getAttribute('photos')
  }

  getViolations(): InspectionViolation['attributes'][] {
    return (this.getAttribute('violations') || [])?.map(
      (violation: InspectionViolation) => violation.attributes,
    )
  }
  getEquipments(): InspectionEquipmentAttributes[] {
    return (this.getAttribute('equipments') || [])?.map(
      (equipment: InspectionEquipment) => equipment.attributes,
    )
  }
  getDocuments(): InspectionDocumentAttributes[] {
    return (this.getAttribute('documents') || [])?.map(
      (document: InspectionDocument) => document.attributes,
    )
  }
  getDamages(): InspectionDamageAttributes[] {
    return (this.getAttribute('damages') || [])?.map(
      (document: InspectionDamage) => document.attributes,
    )
  }

  static async getLastInspections() {
    const url = `${this.getJsonApiUrl()}/actions/last-inspections`
    const res = await this.effectiveHttpClient.get(url)
    const { data } = res.getData()

    return data.map(
      (item: { id: UniqueId; attributes: InspectionAttributes }) =>
        new Inspection(item.attributes, item.id),
    ) as Inspection[]
  }

  static async getPreviousInspection(inspectionsId: UniqueId) {
    const url = `${this.getJsonApiUrl()}/${inspectionsId}/actions/previous-inspection`
    const res = await this.effectiveHttpClient.get(url)
    const { data } = res.getData()
    return data ? new Inspection(data.attributes, data.id) : null
  }
}
