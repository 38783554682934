import * as z from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributeSchema = z.object({
  name: z.string(),
  address: z.string(),
  mapUrl: z.string(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type LocationAttributes = z.infer<typeof attributeSchema>

export class Location extends ApiModel<typeof schema, LocationAttributes> {
  static jsonApiType = 'locations'

  static schema = schema

  getAddress(): string {
    return this.getAttribute('address')
  }
  getName(): string {
    return this.getAttribute('name')
  }
  getMapUrl(): string {
    return this.getAttribute('mapUrl')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }
}
