import { useQuery } from '@tanstack/react-query'
import { BookingCar } from '~/shared/api'
import { Option } from '~/shared/config/constants'

export const useDependentBrandModelOptions = (
  regionId: UniqueId,
  locationIds: string[],
  selectedBrands?: Option[],
) => {
  const { isFetching, data = {} } = useQuery(
    ['brand-model', regionId, locationIds],
    () => BookingCar.getModels(regionId, locationIds),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  )

  const brandOptions = Object.entries(data).map(([brand]) => ({
    id: brand,
    label: brand,
  }))

  const filteredModels = selectedBrands?.length
    ? selectedBrands.reduce<[string, string[]][]>((acc, { id }) => {
        if (data[id]) {
          acc.push([id, data[id]])
        }
        return acc
      }, [])
    : Object.entries(data)

  const modelOptions = filteredModels.reduce(
    (acc: Option[], [brand, models]) => {
      return [
        ...acc,
        ...(models || []).map((model) => ({
          id: model,
          label: model,
          gropedByKey: brand,
        })),
      ]
    },
    [],
  )

  return {
    isFetchingDependentBrandOptions: isFetching,
    brandOptions,
    modelOptions,
  }
}
