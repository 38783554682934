import { styled } from '@mui/material'
import { Link as ReactLink } from 'react-router-dom'
import { myPalette } from '~/shared/ui/theme'
import { WhiteBlock } from '~/shared/ui/Wrappers'

export const Link = styled(ReactLink)`
  flex-direction: row;
  text-decoration: none;
`

export const Wrapper = styled(WhiteBlock)`
  flex-direction: row;
  align-items: center;
  padding: 16px;
`

export const ImageWrapper = styled('div')`
  display: flex;
  margin-right: 12px;
  align-items: center;
`

export const Title = styled('div')`
  font-size: 16px;
  font-weight: 500;
  color: ${myPalette.midnightBlue.main};
`

export const Descriptions = styled('div')`
  font-size: 14px;
  color: ${myPalette.dark.main};
`
