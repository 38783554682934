import { Skeleton } from '~/shared/ui/SkeletonList'

export function LoadingBlock() {
  return (
    <>
      <Skeleton
        animated
        $height={83}
        style={{ marginBottom: '44px', marginTop: 0 }}
      />

      <Skeleton animated $height={62} style={{ marginBottom: '12px' }} />

      <Skeleton animated $height={62} style={{ marginBottom: '12px' }} />

      <Skeleton animated $height={62} style={{ marginBottom: '12px' }} />

      <Skeleton animated $height={62} style={{ marginBottom: '12px' }} />

      <Skeleton animated $height={62} style={{ marginBottom: '12px' }} />
    </>
  )
}
