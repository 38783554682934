import { Unstable_Grid2 as Grid } from '@mui/material'
import { useStore } from 'effector-react'
import { inspectionModel } from '~/entities/Inspection'
import { mileageFormat } from '~/shared/lib/utils'
// import { myPalette } from '~/shared/ui/theme'
import { ItemWrapper, Title, Value } from './styled'

export function Header() {
  const inspection = useStore(inspectionModel.$inspection)

  // const violationsCount = inspection?.getViolations()?.length
  const mileage = inspection?.getMileage()

  return (
    <Grid container spacing={2} flexWrap='nowrap' mb='24px'>
      <Grid xs={12}>
        <ItemWrapper>
          <Title>Пробег</Title>
          <Value>{mileageFormat(mileage)} км</Value>
        </ItemWrapper>
      </Grid>

      {/*<Grid xs={4}>*/}
      {/*  <ItemWrapper>*/}
      {/*    <Title>Нарушения</Title>*/}
      {/*    <Value*/}
      {/*      style={{*/}
      {/*        color: violationsCount*/}
      {/*          ? myPalette.coral.main*/}
      {/*          : myPalette.limeGreen['600'],*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {violationsCount || 0}*/}
      {/*    </Value>*/}
      {/*  </ItemWrapper>*/}
      {/*</Grid>*/}
    </Grid>
  )
}
