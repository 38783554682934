import {
  IonContentCustomEvent,
  menuController,
  ScrollBaseDetail,
} from '@ionic/core/components'
import { IonButton, IonHeader, IonPage } from '@ionic/react'
import { debounce } from 'lodash'
import { createContext, createRef, ReactNode, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { NumberParam, useQueryParam, withDefault } from 'use-query-params'
import { MenuIcon } from '~/shared/ui/icons'
import { myPalette } from '~/shared/ui/theme'
import { ButtonBack } from '../ButtonBack'
import {
  TopContent,
  Content,
  WrapperHeader,
  WrapperCap,
  IonToolbar,
  IonContent,
  Cap,
  ContentWrapper,
} from './styled'

type Props = {
  children: ReactNode
  title: string
  canMenu?: boolean
  canBack?: boolean
  onBack?: () => void
  backTitle?: ReactNode | string
  topContent?: ReactNode
  extraRight?: ReactNode
  preventScrollReset?: boolean
}

export const ScrollContext = createContext<{ scrollToTop: () => void } | null>(
  null,
)

export function PageContainer({
  title,
  canMenu,
  children,
  canBack,
  onBack,
  backTitle,
  topContent,
  extraRight,
  preventScrollReset,
}: Props) {
  const openFirstMenu = async () => {
    await menuController.open('main-menu')
  }
  const contentRef = createRef<HTMLIonContentElement>()

  const [positionTopQuery, setPositionTop] = useQueryParam(
    'pagePositionTop',
    withDefault(NumberParam, 0),
  )

  const handleScrollChangeQuery = debounce(
    (event: IonContentCustomEvent<ScrollBaseDetail>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setPositionTop(event?.target?.detail?.scrollTop, 'replaceIn')
    },
    50,
  )

  const handleScrollToPoint = () => {
    contentRef?.current?.scrollToPoint(0, positionTopQuery, 0)
  }

  const scrollToTop = () => {
    contentRef.current?.scrollToTop(0)
  }

  useEffect(() => {
    if (positionTopQuery) handleScrollToPoint()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ScrollContext.Provider value={{ scrollToTop }}>
      <IonPage
        id='page-container'
        style={{
          background: myPalette.midnightBlue.main,
        }}
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <IonHeader>
          <IonToolbar>
            <WrapperHeader>
              {canMenu && (
                <IonButton
                  fill='clear'
                  onClick={openFirstMenu}
                  data-testid='home-menu-button'
                >
                  <MenuIcon />
                </IonButton>
              )}

              {canBack && (
                <ButtonBack title={backTitle} onBack={onBack} sx={{ mr: 1 }} />
              )}
              {extraRight}
            </WrapperHeader>
          </IonToolbar>
        </IonHeader>

        <IonContent
          fullscreen
          ref={contentRef}
          scrollEvents={preventScrollReset}
          onIonScroll={handleScrollChangeQuery}
        >
          <ContentWrapper>
            {topContent ? (
              <TopContent>{topContent}</TopContent>
            ) : (
              <div style={{ padding: '9px' }} />
            )}

            <WrapperCap>
              <Cap />
            </WrapperCap>
            <Content>{children}</Content>
          </ContentWrapper>
        </IonContent>
      </IonPage>
    </ScrollContext.Provider>
  )
}
