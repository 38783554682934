import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { rentalContractModel } from '~/entities/RentalContract'
import { AxiosErrorType, RentalContact } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { notificationModalOpen } from '~/shared/lib/notificationModal'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { openBlobFile } from '~/shared/lib/openBlobFile'

const domain = createDomain('features.rentalContractSigning')
export const Gate = createGate()

// Confirm code
export const confirmCode = domain.createEvent<{
  fn: () => void
}>()
export const confirmCodeFx = domain.createEffect<
  { fn: () => void },
  string,
  AxiosErrorType
>({
  handler: async ({ fn }) => {
    const res = await RentalContact.confirmCode()
    fn()
    return res?.message
  },
})

sample({
  clock: confirmCode,
  target: confirmCodeFx,
})

sample({
  clock: confirmCodeFx.doneData,
  fn(message) {
    return {
      message: message,
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: confirmCodeFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

// Confirm
export const confirm = domain.createEvent<{
  code: string
  fn: () => void
}>()
export const confirmFx = domain.createEffect<
  { code: string; fn: () => void },
  void,
  AxiosErrorType
>({
  handler: async ({ code, fn }) => {
    await RentalContact.confirm(code)
    fn()
  },
})

sample({
  clock: confirm,
  target: confirmFx,
})

sample({
  clock: confirmFx.doneData,
  fn() {
    return {
      title: 'Договор подписан',
      description:
        'Договор появится в приложение в течение 10 минут. Его можно найти в разделе меню «Документы»',
      variant: 'success' as const,
    }
  },
  target: [
    notificationModalOpen,
    rentalContractModel.getRentalContractSilentFx,
  ],
})

sample({
  clock: confirmFx.failData,
  fn(e) {
    return {
      title: 'Не удалось подписать договор',
      description: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: notificationModalOpen,
})

// Rental contract file
export const loadRentalContractFile = domain.createEvent()
export const loadRentalContractFileFx = domain.createEffect<
  void,
  Blob,
  AxiosErrorType
>({
  handler: async () => {
    return await RentalContact.file()
  },
})

const openFileFx = domain.createEffect<Blob, void>({
  handler: async (blob) => {
    await openBlobFile(blob, 'Договор аренды.pdf')
  },
})

sample({
  clock: loadRentalContractFile,
  target: loadRentalContractFileFx,
})

sample({
  clock: loadRentalContractFileFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: loadRentalContractFileFx.doneData,
  target: openFileFx,
})

sample({
  clock: openFileFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка, невозможно просмотреть договор аренды!',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})
