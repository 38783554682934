import { profileModel } from '~/widgets//Profile'
import { carVehicleCategoryModel } from '~/widgets/CarVehicleCategoryDrawer'
// import { notificationsModel } from '~/widgets/Notifications'
import { documentsModel } from '~/widgets/Documents'
import { useMainTaxiparks } from '~/widgets/MainTaxipark'
import { penaltiesModel } from '~/widgets/Penalties'
import { supportModel } from '~/widgets/Support'
import { viewerModel } from '~/entities/viewer'
import { canShowObject } from '~/shared/lib/utils'
import {
  MenuTouchIDIcon,
  // MenuNotificationsIcon,
  MenuCameraIcon,
  // MenuFireIcon,
  MenuQuestionIcon,
  // MenuPresentIcon,
  MenuLogoutIcon,
  MenuAppRegistrationIcon,
  MenuDocumentsIcon,
  MenuCarIcon,
  InspectionsIcon,
} from '~/shared/ui/icons'

import { MenuType } from '../type'

export const useMenu = (): MenuType[] => {
  const mainTaxiparks = useMainTaxiparks()
  const hasOnlineRental = mainTaxiparks?.hasOnlineRental
  const isInspectionsVisible = mainTaxiparks?.isInspectionsVisible

  return [
    ...canShowObject(Boolean(hasOnlineRental), {
      title: 'Каталог авто',
      icon: <MenuCarIcon />,
      fn: carVehicleCategoryModel.openCarVehicleCategoryDrawer,
    }),
    {
      title: 'Личные данные',
      icon: <MenuTouchIDIcon />,
      fn: profileModel.openProfileDrawer,
    },
    // {
    //   title: 'Уведомления',
    //   icon: <MenuNotificationsIcon />,
    //   fn: notificationsModel.openNotificationsDrawer,
    //   notification: true,
    // },
    {
      title: 'Штрафы',
      icon: <MenuCameraIcon />,
      fn: penaltiesModel.openPenaltiesDrawer,
      notification: true,
    },
    // {
    //   title: 'Программа лояльности',
    //   icon: <MenuPresentIcon />,
    //   to: '/',
    //   disabled: true,
    // },
    // {
    //   title: 'Частые вопросы',
    //   icon: <MenuFireIcon />,
    //   to: '/',
    //   disabled: true,
    // },
    {
      title: 'Поддержка',
      icon: <MenuQuestionIcon />,
      fn: supportModel.openSupportDrawer,
    },
    {
      title: 'Документы',
      icon: <MenuDocumentsIcon />,
      fn: documentsModel.openDocumentsDrawer,
    },
    ...canShowObject(Boolean(isInspectionsVisible), {
      title: 'Осмотры',
      icon: <InspectionsIcon />,
      to: '/inspections',
    }),
    {
      title: 'Запись на осмотр',
      icon: <MenuAppRegistrationIcon />,
      href: 'https://инфо369.рф/select',
    },
    {
      title: 'Выйти',
      icon: <MenuLogoutIcon />,
      fn: viewerModel.signOut,
    },
  ]
}
