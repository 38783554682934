import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { AxiosErrorType, RentalContact } from '~/shared/api'
import { loadingModel } from '~/shared/lib/loading'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { openBlobFile } from '~/shared/lib/openBlobFile'

const domain = createDomain('widgets.documents.ui.rentalContract')
export const Gate = createGate()

const openFileFx = domain.createEffect<{ blob: Blob; name: string }, void>({
  handler: async ({ blob, name }) => {
    await openBlobFile(blob, name)
  },
})

sample({
  clock: openFileFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка, невозможно просмотреть файл!',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

// Rental contract file
export const loadRentalContractFile = domain.createEvent()
export const loadRentalContractFileFx = domain.createEffect<
  void,
  Blob,
  AxiosErrorType
>({
  handler: async () => {
    return await RentalContact.file()
  },
})

sample({
  clock: loadRentalContractFile,
  target: loadRentalContractFileFx,
})

sample({
  clock: loadRentalContractFileFx.pending,
  fn: () => ({
    message: 'Загрузка договора аренды',
  }),
  target: loadingModel.present,
})

sample({
  clock: loadRentalContractFileFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: loadRentalContractFileFx.finally,
  target: loadingModel.dismiss,
})

sample({
  clock: loadRentalContractFileFx.doneData,
  fn: (blob) => ({ blob, name: 'Договор аренды.pdf' }),
  target: openFileFx,
})

// Rental contract confirm file
export const loadRentalContractConfirmFile = domain.createEvent()
export const loadRentalContractConfirmFileFx = domain.createEffect<
  void,
  Blob,
  AxiosErrorType
>({
  handler: async () => {
    return await RentalContact.confirmFile()
  },
})

sample({
  clock: loadRentalContractConfirmFile,
  target: loadRentalContractConfirmFileFx,
})

sample({
  clock: loadRentalContractConfirmFileFx.pending,
  fn: () => ({
    message: 'Загрузка подписи договора аренды',
  }),
  target: loadingModel.present,
})

sample({
  clock: loadRentalContractConfirmFileFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: loadRentalContractConfirmFileFx.finally,
  target: loadingModel.dismiss,
})

sample({
  clock: loadRentalContractConfirmFileFx.doneData,
  fn: (blob) => ({ blob, name: 'Подпись договора аренды.pdf' }),
  target: openFileFx,
})
