import { styled } from '@mui/material'

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 24px 0;
`

export const Title = styled('div')`
  font-size: 18px;
  font-weight: 700;
`

export const TypeTitle = styled('div')`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`

export const EmptyPhotoTitle = styled('div')`
  font-size: 14px;
`
