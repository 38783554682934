import { AccordionGroupCustomEvent, IonAccordionGroup } from '@ionic/react'
import { ReactNode, useEffect, useState } from 'react'

type AccordionGroupProps<T> = {
  defaultValue?: string[]
  children: ReactNode
  onChange: (values: T[]) => void
}

export function AccordionGroup<T>({
  defaultValue,
  children,
  onChange,
}: AccordionGroupProps<T>) {
  const [isAnimated, setIsAnimated] = useState(false)
  const handleOnChange = (event: AccordionGroupCustomEvent) => {
    onChange(event.detail.value)
  }

  useEffect(() => {
    setIsAnimated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IonAccordionGroup
      value={defaultValue}
      multiple
      animated={isAnimated}
      onIonChange={handleOnChange}
    >
      {children}
    </IonAccordionGroup>
  )
}
