import { LoadingButton as MuiLoadingButton } from '@mui/lab'
import {
  styled,
  Skeleton as MuiSkeleton,
  Button as MuiButton,
} from '@mui/material'

export const ButtonsWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
`

export const LoadingButton = styled(MuiLoadingButton)`
  padding: 4px 8px;
  font-size: 14px;
`

export const Button = styled(MuiButton)`
  padding: 4px 8px;
  font-size: 14px;
` as typeof MuiButton

export const Skeleton = styled(MuiSkeleton)`
  height: 36px;
  width: 100%;
  border-radius: 6px;
`
