import { createEnumDelimitedArrayParam } from 'use-query-params'
import { CheckTypesEnum } from '~/entities/Inspection'
import {
  Inspection,
  InspectionDocumentAttributes,
  InspectionEquipmentAttributes,
} from '~/shared/api'
import { documentsCodeEnumLabels } from '~/shared/config/constants'

export enum AccordionKeys {
  damages = 'damages',
  cabin = 'cabin',
  documents = 'documents',
  equipments = 'equipments',
  wheels = 'wheels',
}

export const DelimitedAccordionArrayParam = createEnumDelimitedArrayParam<
  keyof typeof AccordionKeys
>(Object.values(AccordionKeys))

const getCheckType = (value?: boolean, valueOld?: boolean): CheckTypesEnum => {
  if (!value && !valueOld) return CheckTypesEnum.cancel
  if (value) return CheckTypesEnum.success

  return CheckTypesEnum.error
}

export const getBody = (
  inspection: Inspection | null,
  previousInspection: Inspection | null,
): { type: CheckTypesEnum; label: string }[] => {
  const hasTobaccoSmell = inspection?.getHasTobaccoSmell()
  const isDirtyEnterior = inspection?.getIsDirtyEnterior()
  const isDryCleaningNeed = inspection?.getIsDryCleaningNeed()

  const previousHasTobaccoSmell = previousInspection?.getHasTobaccoSmell()
  const previousIsDirtyEnterior = previousInspection?.getIsDirtyEnterior()
  const previousIsDryCleaningNeed = previousInspection?.getIsDryCleaningNeed()

  return [
    {
      type: getCheckType(!hasTobaccoSmell, !previousHasTobaccoSmell),
      label: 'Запах табака нет',
    },
    {
      type: getCheckType(!isDirtyEnterior, !previousIsDirtyEnterior),
      label: 'Автомобиль чистый',
    },
    {
      type: getCheckType(!isDryCleaningNeed, !previousIsDryCleaningNeed),
      label: 'Химчистка не требуется',
    },
  ]
}

export const getDocuments = (
  documents?: InspectionDocumentAttributes[],
  previousDocuments?: InspectionDocumentAttributes[],
): { type: CheckTypesEnum; label: string }[] => {
  return (documents || []).map((document) => {
    const previousValue = previousDocuments?.find(
      ({ type }) => type === document.type,
    )?.value

    return {
      type: getCheckType(document.value, previousValue),
      label: documentsCodeEnumLabels[document.type],
    }
  })
}

export const getEquipments = (
  equipments?: InspectionEquipmentAttributes[],
  PreviousEquipments?: InspectionEquipmentAttributes[],
): { type: CheckTypesEnum; label: string }[] => {
  return (equipments || []).map((equipment) => {
    const previousValue = PreviousEquipments?.find(
      ({ equipmentId }) => equipmentId === equipment.equipmentId,
    )?.value

    return {
      type: getCheckType(equipment.value, previousValue),
      label: equipment.title,
    }
  })
}
