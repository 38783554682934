import { IonToast } from '@ionic/react'
import { snackbarClosed } from '~/shared/lib/notifications'
import { DefaultNotification } from '../model'

type DefaultToastProps = DefaultNotification & { id: UniqueId }

export function DefaultToast({ id, message, variant }: DefaultToastProps) {
  return (
    <IonToast
      isOpen
      message={message}
      duration={30000}
      position='top'
      swipeGesture='vertical'
      buttons={[
        {
          text: 'Закрыть',
        },
      ]}
      color={variant}
      translucent
      onDidDismiss={() => snackbarClosed(id)}
    />
  )
}
