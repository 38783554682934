import { Button } from '@mui/material'
import { useStore } from 'effector-react'
import { useContext } from 'react'
import { inspectionModel } from '~/entities/Inspection'
import { pushFx } from '~/shared/lib/history'
import { ScrollContext } from '~/shared/ui/PageContainer'

export function GoToPreviousInspection() {
  const previousInspection = useStore(inspectionModel.$previousInspection)
  const previousInspectionId = previousInspection?.getApiId()
  const context = useContext(ScrollContext)

  const handleGoTo = () => {
    context?.scrollToTop()
    pushFx(`/inspections/${previousInspectionId}`)
  }

  if (!previousInspection) return null

  return (
    <Button fullWidth variant='limeFlooded' size='large' onClick={handleGoTo}>
      Чек-лист предыдущего осмотра
    </Button>
  )
}
