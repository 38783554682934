import { IonHeader } from '@ionic/react'
import { LoadingButton } from '@mui/lab'
import { Box, Stack } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { driverModel } from '~/entities/Driver'
import { viewerModel } from '~/entities/viewer'
import { backFx } from '~/shared/lib/history'
import { SuccessSmallIcon } from '~/shared/ui/icons'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { myPalette } from '~/shared/ui/theme'
import { setMainTaxipark, setMainTaxiparkFx } from './model'
import {
  Title,
  IonToolbar,
  HeaderWrapper,
  IonPage,
  IonContent,
  Button,
  ButtonWrapper,
  IonFooter,
  LoadingWrapper,
  CardBlock,
} from './styled'

export function ListTaxiparks() {
  useGate(driverModel.Gate)

  const [selectedTaxiparkId, setSelectedTaxiparkId] = useState<string>()
  const driverPending = useStore(driverModel.getDriverFx.pending)
  const isSubmitting = useStore(setMainTaxiparkFx.pending)
  const driver = useStore(driverModel.$driver)

  const mainTaxiparkId = driver?.getMainTaxiparkId()

  const taxiparks = useMemo(
    () =>
      (driver?.getTaxiparks() || []).map((taxipark) => ({
        ...taxipark.getAttributes(),
        id: taxipark.getApiId() as string,
      })),
    [driver],
  )

  useEffect(() => {
    if (!mainTaxiparkId) return

    setSelectedTaxiparkId(mainTaxiparkId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainTaxiparkId])

  const handleTaxiparkSelect = (id: UniqueId) => {
    setSelectedTaxiparkId(id)
  }

  const handleSubmit = () => {
    setMainTaxipark(selectedTaxiparkId as UniqueId)
  }

  if (driverPending) {
    return (
      <LoadingWrapper>
        <ProgressBlock />
      </LoadingWrapper>
    )
  }

  return (
    <IonPage>
      <Helmet>
        <title>Таксопарки</title>
      </Helmet>

      <IonHeader>
        <IonToolbar>
          <HeaderWrapper>
            <ButtonWrapper>
              {mainTaxiparkId ? (
                <Button onClick={() => backFx()}>Назад</Button>
              ) : (
                <Button onClick={() => viewerModel.signOut()}>Выйти</Button>
              )}
            </ButtonWrapper>
            <Title>Выберите таксопарк</Title>
          </HeaderWrapper>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Stack spacing={2}>
          {taxiparks.map((taxipark) => (
            <CardBlock
              onClick={() => handleTaxiparkSelect(taxipark.id)}
              key={taxipark.id}
              sx={{
                ...(taxipark.id === selectedTaxiparkId
                  ? { outline: `1px solid ${myPalette.blue.main}` }
                  : {}),
              }}
            >
              <Box sx={{ marginRight: '6px' }}>{taxipark.name}</Box>
              {mainTaxiparkId === taxipark.id && <SuccessSmallIcon />}
            </CardBlock>
          ))}
        </Stack>
      </IonContent>

      <IonFooter>
        <LoadingButton
          fullWidth
          onClick={handleSubmit}
          variant='limeFlooded'
          size='large'
          loading={isSubmitting}
          disabled={!selectedTaxiparkId}
        >
          Подтвердить
        </LoadingButton>
      </IonFooter>
    </IonPage>
  )
}
