import { IonChip, IonLabel } from '@ionic/react'
import { LoadingButton } from '@mui/lab'
import { useStore, useStoreMap } from 'effector-react'
import { useForm } from 'react-hook-form'
import {
  Form,
  Title,
  Characteristics,
  Photos,
  FormValues,
  BookingCarDrawer,
  Address,
} from '~/entities/BookingCar'
import { rentalContractModel } from '~/entities/RentalContract'
import { viewerModel } from '~/entities/viewer'
import { RentalContractWorkScheduleEnum } from '~/shared/config/enums'
import { notificationModalOpen } from '~/shared/lib/notificationModal'
import { useModal } from '~/shared/ui/Modal'
import {
  $bookingCar,
  rentalContactCreationFormSubmit,
  rentalContactCreationRequestFx,
} from './model'
import {
  BookingPhone,
  BookingPhoneDescriptions,
  BookingPhoneTitle,
  BookingPhoneWrapper,
  Wrapper,
} from './styled'

const defaultValues = {
  workSchedule: {
    id: RentalContractWorkScheduleEnum.FULL_WEEK,
    label: RentalContractWorkScheduleEnum.FULL_WEEK,
  },
  dayOff: null,
}

export function BookingCar() {
  const authenticated = viewerModel.useAuthenticated()

  const { isModalShow, closeModal, openModal } = useModal()
  const bookingCar = useStore($bookingCar)
  const isSubmitting = useStore(rentalContactCreationRequestFx.pending)
  const cancellationFeeRub = bookingCar?.getCancellationFeeRub()

  const location = useStoreMap($bookingCar, (bookingCar) =>
    bookingCar?.getLocation(),
  )
  const rentalContract = useStore(rentalContractModel.$rentalContract)
  const rentalContractPending = useStore(
    rentalContractModel.getRentalContractFx.pending,
  )

  const form = useForm<FormValues>({
    defaultValues,
  })
  const { getValues } = form

  const handleBooking = () => {
    const formValues = getValues()
    rentalContactCreationFormSubmit({ formValues, closeModal })
  }

  const handleBookingModalOpen = async () => {
    if (rentalContract) {
      notificationModalOpen({
        description: 'Нельзя забронировать автомобиль с активным договором',
        variant: 'warning',
        buttonTitle: 'Понятно',
      })
    } else {
      openModal()
    }
  }

  if (!bookingCar) return null

  const getPlateNumber = bookingCar.getPlateNumber()
  const pricePerDay = bookingCar.getPricePerDay()
  const photos = bookingCar.getPhotos()
  const isLeasingFullyPaid = bookingCar.getIsLeasingFullyPaid()

  return (
    <Wrapper>
      <Photos
        plateNumber={getPlateNumber}
        photos={photos}
        isLeasingFullyPaid={isLeasingFullyPaid}
      />

      <div>
        <Title>Стоимость аренды</Title>
        <Form pricePerDay={pricePerDay} instanceForm={form} />
      </div>

      <Characteristics bookingCar={bookingCar} />

      <Address name={location?.getName()} />

      <LoadingButton
        variant='limeFlooded'
        size='large'
        fullWidth
        onClick={handleBookingModalOpen}
        loading={rentalContractPending}
      >
        Забронировать на 2 часа
      </LoadingButton>

      <BookingCarDrawer
        isShow={isModalShow}
        onCancel={closeModal}
        location={location}
        disabled={isSubmitting}
        cancellationFeeRub={cancellationFeeRub}
      >
        {authenticated ? (
          <LoadingButton
            size='large'
            data-testid='button-booking-car-drawer'
            fullWidth
            variant='limeFlooded'
            onClick={handleBooking}
            sx={{ marginBottom: '8px' }}
            loading={isSubmitting}
          >
            Забронировать
          </LoadingButton>
        ) : (
          <>
            <IonChip color='secondary' style={{ marginBottom: '16px' }}>
              <IonLabel>
                Вам необходимо разово пройти процедуру проверки и регистрации в
                офисе, чтобы аренда стала доступна. Подробности можете уточнить
                по телефону
              </IonLabel>
            </IonChip>

            <BookingPhoneWrapper href='tel:+79344770800'>
              <BookingPhoneTitle>Аренда авто по телефону:</BookingPhoneTitle>
              <BookingPhone>+7 (934) 477-08-00</BookingPhone>
              <BookingPhoneDescriptions>
                В Москве офис работает по будням 9:00–18:00 и в сб 10:00–16:00;
                в регионах — по будням 9:00–18:00, выходные — сб и вс.
              </BookingPhoneDescriptions>
            </BookingPhoneWrapper>
          </>
        )}
      </BookingCarDrawer>
    </Wrapper>
  )
}
