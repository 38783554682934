import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormContainer } from 'react-hook-form-mui'
import {
  CURRENCY,
  daysEnumLabels,
  daysEnumOptions,
  rentalContractWorkScheduleEnumOptions,
} from '~/shared/config/constants'
import { RentalContractWorkScheduleEnum } from '~/shared/config/enums'
import { getDay } from '~/shared/lib/date'
import { priceFormat } from '~/shared/lib/utils'
import { Select } from '~/shared/ui/form'
import { FormValues } from './model'
import { Price } from './styled'

export type FormProps = {
  pricePerDay: number
  instanceForm: UseFormReturn<FormValues>
}

export function Form({ pricePerDay, instanceForm }: FormProps) {
  const { watch, setValue } = instanceForm

  const workSchedule = watch('workSchedule')

  const isSixThroughOne =
    workSchedule?.id === RentalContractWorkScheduleEnum.SIX_THROUGH_ONE

  const price = useMemo(() => {
    if (isSixThroughOne) {
      return (pricePerDay * 7) / 6
    }
    return pricePerDay
  }, [isSixThroughOne, pricePerDay])

  const mappedDaysEnumOptions = useMemo(() => {
    const today = getDay('current')

    return daysEnumOptions.map((option) =>
      option.id === today ? { ...option, disabled: true } : option,
    )
  }, [])

  watch((data, { type, name }) => {
    if (type !== 'change') return

    if (name === 'workSchedule') {
      const id = data.workSchedule?.id

      if (id === RentalContractWorkScheduleEnum.FULL_WEEK) {
        setValue('dayOff', null)
      }

      if (id === RentalContractWorkScheduleEnum.SIX_THROUGH_ONE) {
        const yesterday = getDay('yesterday')
        setValue('dayOff', { id: yesterday, label: daysEnumLabels[yesterday] })
      }
    }
  })

  return (
    <>
      <FormContainer formContext={instanceForm}>
        <Select
          options={rentalContractWorkScheduleEnumOptions}
          name='workSchedule'
          label='Выберите график'
        />
        <br />

        {isSixThroughOne && (
          <Select
            options={mappedDaysEnumOptions}
            name='dayOff'
            label='Выходной день'
          />
        )}

        <Price data-testid='booking-car-price'>
          {priceFormat(price)} {CURRENCY} / сутки
        </Price>
      </FormContainer>
    </>
  )
}
