import { Button } from '@mui/material'
import { ReactNode } from 'react'
import { Location } from '~/shared/api'
import { priceFormat } from '~/shared/lib/utils'
import { DrawerComponent } from '~/shared/ui/Drawer'
import {
  ClockIcon,
  RubGrayIcon,
  AddressGrayIcon,
  PlusSquareIcon,
} from '~/shared/ui/icons'
import { myPalette } from '~/shared/ui/theme'
import {
  AlertDescriptions,
  AlertIcon,
  AlertWrapper,
  ButtonsWrapper,
  Title,
} from './styled'

type BookingCarDrawerProps = {
  isShow: boolean
  onCancel: () => void
  disabled?: boolean
  location?: Location
  children?: ReactNode
  cancellationFeeRub?: number | null
}

export function BookingCarDrawer({
  isShow,
  onCancel,
  disabled,
  location,
  children,
  cancellationFeeRub,
}: BookingCarDrawerProps) {
  return (
    <DrawerComponent
      isShow={isShow}
      onCancel={onCancel}
      contentProps={{
        className: 'ion-content-scroll-host',
        sx: {
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
    >
      <div>
        <Title>Бронирование на 2 часа</Title>
        <AlertWrapper>
          <AlertIcon>
            <ClockIcon />
          </AlertIcon>
          <AlertDescriptions>
            В течение 2 часов подпишите договор и приезжайте на площадку за
            автомобилем
          </AlertDescriptions>
        </AlertWrapper>

        <AlertWrapper>
          <AlertIcon>
            <RubGrayIcon />
          </AlertIcon>
          <AlertDescriptions>
            Списания начнутся только после прохождения осмотра и получения
            автомобиля.{' '}
            {cancellationFeeRub && (
              <>
                В случае, если вы не успеете за отведенное время, с вас будет
                списана плата за бронирование автомобиля в размере{' '}
                {priceFormat(cancellationFeeRub)} руб.
              </>
            )}
          </AlertDescriptions>
        </AlertWrapper>

        <AlertWrapper>
          <AlertIcon>
            <PlusSquareIcon />
          </AlertIcon>
          <AlertDescriptions>
            Обратите внимание, что дополнительно к стоимости аренды будут
            добавлены обязательные услуги: автокаско, мед. осмотр и тех.осмотр.
            Списания по этим услугам происходят ежедневно.
          </AlertDescriptions>
        </AlertWrapper>

        <AlertWrapper>
          <AlertIcon>
            <AddressGrayIcon />
          </AlertIcon>
          <AlertDescriptions>
            <div style={{ color: myPalette.dark.main }}>Адрес</div>
            {location ? (
              <>
                <div>{location.getName()}</div>
                <div>{location.getAddress()}</div>

                <Button
                  href={location.getMapUrl()}
                  target='_blank'
                  variant='text'
                  sx={{ p: 0 }}
                >
                  Карта проезда
                </Button>
              </>
            ) : (
              <div>Адрес не найден! Уточните у оператора</div>
            )}
          </AlertDescriptions>
        </AlertWrapper>
      </div>

      <ButtonsWrapper>
        {children}

        <Button
          size='large'
          fullWidth
          variant='lightSteelBlueBorder'
          onClick={onCancel}
          disabled={disabled}
        >
          Закрыть
        </Button>
      </ButtonsWrapper>
    </DrawerComponent>
  )
}
