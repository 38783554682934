import { createDomain, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { interval } from 'patronum'
import { AxiosErrorType, RentalContact } from '~/shared/api'
import { isDevEnv } from '~/shared/config/env'

export const Gate = createGate()
const domain = createDomain('entities.rentalContract')

const getRentalContract = domain.createEvent()
export const getRentalContractFx = domain.createEffect<
  void,
  RentalContact,
  AxiosErrorType
>({
  handler: RentalContact.my,
})

export const getRentalContractSilent = domain.createEvent()
export const getRentalContractSilentFx = domain.createEffect({
  handler: RentalContact.my,
})

export const $rentalContract = createStore<RentalContact | null>(null)
  .on(
    [getRentalContractFx.doneData, getRentalContractSilentFx.doneData],
    (_, rentalContract) => rentalContract,
  )
  .on(
    [
      getRentalContractFx.failData,
      getRentalContractSilentFx.failData,
      Gate.close,
    ],
    () => null,
  )

sample({
  clock: Gate.open,
  target: getRentalContract,
})

sample({
  clock: getRentalContract,
  target: getRentalContractFx,
})

sample({
  clock: getRentalContractSilent,
  target: getRentalContractSilentFx,
})

const intervalRequestStarted = domain.createEvent()
const intervalRequestStopped = domain.createEvent()

if (!isDevEnv) {
  const { tick } = interval({
    timeout: 60000,
    start: intervalRequestStarted,
    stop: intervalRequestStopped,
    leading: false,
    trailing: false,
  })

  sample({
    clock: tick,
    target: getRentalContractSilentFx,
  })

  sample({
    clock: Gate.open,
    target: intervalRequestStarted,
  })
  sample({
    clock: Gate.close,
    target: intervalRequestStopped,
  })
}
