import { useGate, useStoreMap } from 'effector-react'
import { useParams } from 'react-router-dom'
import {
  ViewInspection,
  LoadingBlock,
} from '~/features/Inspections/ViewInspection'
import { inspectionModel } from '~/entities/Inspection'
import { formatDateForUI } from '~/shared/lib/date'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { NotFound } from '~/shared/ui/NotFound'
import { PageContainer } from '~/shared/ui/PageContainer'

export const ViewInspectionPage = () => {
  const params = useParams<{ inspectionId: UniqueId }>()
  const paramsInspectionId = params.inspectionId as UniqueId

  useGate(inspectionModel.Gate, { id: paramsInspectionId })

  const inspectionId = useStoreMap(inspectionModel.$inspection, (inspection) =>
    inspection?.getApiId(),
  )
  const inspectionFinishedAt = useStoreMap(
    inspectionModel.$inspection,
    (inspection) => inspection?.getFinishedAt(),
  )

  const inspectionTitle = `Чек-лист осмотра ${
    inspectionFinishedAt && formatDateForUI(inspectionFinishedAt)
  }`
  const inspectionLoaded = inspectionId === paramsInspectionId
  const title = `${inspectionLoaded ? inspectionTitle : ''}`

  const displayName = inspectionLoaded ? (
    inspectionTitle
  ) : (
    <ProgressBlock height={20} size={16} />
  )

  const inspectionError = inspectionModel.useInspectionError(paramsInspectionId)
  if (inspectionError) {
    return <NotFound message='Не удалось загрузить осмотр' />
  }

  return (
    <PageContainer
      title={title}
      backTitle={displayName}
      canBack
      preventScrollReset
    >
      {inspectionLoaded ? <ViewInspection /> : <LoadingBlock />}
    </PageContainer>
  )
}
