import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'
import { WhiteBlock } from '~/shared/ui/Wrappers'

export const CloseWrapper = styled(WhiteBlock)`
  width: auto;
  padding: 7px 10px;
  border-radius: 8px;
  font-size: 14px;
`

export const Wrapper = styled(WhiteBlock)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: ${myPalette.black.main};
  cursor: pointer;
  margin-bottom: 16px;
`

export const Title = styled('div')`
  font-size: 18px;
  font-weight: 700;
`

export const Phone = styled(Title)`
  font-weight: 400;
`

export const Descriptions = styled('div')`
  font-size: 12px;
`

export const Timer = styled('div')`
  color: ${myPalette.dark.main};
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
`
