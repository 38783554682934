import { Button } from '@mui/material'
import { useStore } from 'effector-react'
import { Link } from 'react-router-dom'
import { rentalContractModel } from '~/entities/RentalContract'
import { RentalContractStatusEnum } from '~/shared/config/enums'
import { myPalette } from '~/shared/ui/theme'
import { Wrapper, Title, Description } from './styled'

export function RentalContractSigningAlert() {
  const rentalContract = useStore(rentalContractModel.$rentalContract)

  const status = rentalContract?.getStatus()

  const isSigning = status === RentalContractStatusEnum.SIGNING

  if (!rentalContract || !isSigning) return null

  return (
    <Wrapper data-testid='rental-contract-signing-alert'>
      <Title>Новый договор</Title>
      <Description>Поступил договор на подписание</Description>

      <Button
        variant='text'
        component={Link}
        to='/rental-contract-signing'
        sx={{
          padding: '0',
          color: myPalette.midnightBlue['700'],
          fontSize: '16px',
        }}
      >
        Открыть
      </Button>
    </Wrapper>
  )
}
