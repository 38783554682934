import { BigInfoNotification, snackbarClosed } from '../model'
import { IonToast } from './styled'

type BigInfoToastProps = BigInfoNotification & { id: UniqueId }

export function BigInfoToast({
  id,
  title,
  message,
  actions,
  buttons,
}: BigInfoToastProps) {
  return (
    <IonToast
      isOpen
      header={title}
      message={message}
      layout='stacked'
      position='top'
      swipeGesture='vertical'
      buttons={
        buttons || [
          {
            text: 'Подробнее',
            handler: () => actions?.(),
          },
          {
            text: 'Скрыть',
            role: 'cancel',
          },
        ]
      }
      translucent
      onDidDismiss={() => snackbarClosed(id)}
    />
  )
}
