import { InfoSmallIcon } from '~/shared/ui/icons'
import { myPalette } from '~/shared/ui/theme'
import {
  Circle,
  Degree,
  Img,
  ItemsList,
  ItemWrapper,
  IconWrapper,
} from './styled'

export function Legend() {
  return (
    <ItemsList>
      <IconWrapper>
        <InfoSmallIcon />
      </IconWrapper>
      <ItemWrapper>
        <Img src='/images/marker.svg' alt='marker' /> С данного осмотра
      </ItemWrapper>
      <ItemWrapper>
        <Circle $background={myPalette.orange.main} /> С прошлых осмотров
      </ItemWrapper>

      <ItemWrapper>
        <Degree>Л</Degree> - Легкое
      </ItemWrapper>

      <ItemWrapper>
        <Degree>Т</Degree> - Тяжелое
      </ItemWrapper>
    </ItemsList>
  )
}
