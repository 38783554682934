import { Button } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { Option } from '~/shared/config/constants'
import { Title, ButtonsWrapper } from './styled'

type MultiButtonsProps = {
  field: string
  title: string
  options: Option[]
}

export function MultiButtons({ field, title, options }: MultiButtonsProps) {
  const { setValue, watch } = useFormContext()

  const valuesForm: Option[] = watch(field) || []

  const handleSelectedValueCheck = (id: UniqueId) => {
    return valuesForm.some((selectedValue) => selectedValue?.id === id)
  }

  const handleChange = (option: Option) => {
    if (handleSelectedValueCheck(option.id)) {
      setValue(
        field,
        valuesForm.filter((valueForm) => valueForm?.id !== option.id),
      )
    } else {
      setValue(field, [...valuesForm, option])
    }
  }

  return (
    <div>
      <Title>{title}</Title>

      <ButtonsWrapper>
        {options.map((option) => (
          <Button
            variant={
              handleSelectedValueCheck(option.id)
                ? 'blueFlooded'
                : 'whiteFlooded'
            }
            sx={{ padding: '8px 12px', borderRadius: '12px' }}
            onClick={() => handleChange(option)}
            key={option.id}
          >
            {option.label}
          </Button>
        ))}
      </ButtonsWrapper>
    </div>
  )
}
