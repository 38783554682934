import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '@mui/material'
import { ButtonTypeMap } from '@mui/material/Button/Button'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { FunctionComponent, ReactNode } from 'react'
import { backFx } from '~/shared/lib/history'

type ButtonBackProps = Partial<ButtonTypeMap['props']> & {
  sx?: SxProps<Theme>
  onBack?: () => void
  title?: ReactNode | string
  showIcon?: boolean
}

export const ButtonBack: FunctionComponent<ButtonBackProps> = ({
  sx,
  onBack,
  title = 'Назад',
  showIcon = true,
}) => {
  const backFn = onBack || backFx

  return (
    <Button
      onClick={() => backFn()}
      startIcon={showIcon && <ArrowBackIcon />}
      sx={{
        fontSize: '18px',
        '.MuiButton-startIcon': {
          padding: '10px',
          background: '#1D2F57',
          borderRadius: '100%',
          mr: '16px',
        },
        ...sx,
      }}
      color='white'
    >
      {title}
    </Button>
  )
}
