import { styled, Typography } from '@mui/material'

export const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
`

export const AlertWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
`

export const AlertIcon = styled('div')`
  margin-top: 4px;
`

export const AlertDescriptions = styled('div')`
  font-size: 16px;
  margin-left: 10px;
`

export const ButtonsWrapper = styled('div')``
