import { Box } from '@mui/material'
import { useStore } from 'effector-react'
import {
  CarImage,
  Descriptions,
  EmptyCar,
  Booking,
} from '~/entities/RentalContract'
import { rentalContractModel } from '~/entities/RentalContract'
import { RentalContractStatusEnum } from '~/shared/config/enums'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { SignedWarning } from './ui/SignedWarning'

export function RentalContract() {
  const rentalContractPending = useStore(
    rentalContractModel.getRentalContractFx.pending,
  )
  const rentalContract = useStore(rentalContractModel.$rentalContract)
  const carImage = rentalContract?.getCarImage()

  const status = rentalContract?.getStatus()
  const isSigning = status === RentalContractStatusEnum.SIGNING
  const isSigned = status === RentalContractStatusEnum.SIGNED

  if (rentalContractPending) return <ProgressBlock height={364} />
  if (!rentalContractPending && !rentalContract) return <EmptyCar />

  return (
    <Box data-testid={`main-booking-car-block-${status}`}>
      {carImage && <CarImage src={carImage} />}

      {(isSigning || isSigned) && <Booking />}

      {isSigned && <SignedWarning />}

      <Descriptions />
    </Box>
  )
}
