export function InfoSmallIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='16' height='16' rx='8' fill='#B2BDD6' />
      <path
        d='M7.95039 12C7.80511 11.9982 7.66632 11.9427 7.56358 11.8452C7.46085 11.7477 7.40229 11.6161 7.40039 11.4783V6.6087C7.40039 6.47032 7.45834 6.33762 7.56148 6.23977C7.66463 6.14193 7.80452 6.08696 7.95039 6.08696C8.09626 6.08696 8.23615 6.14193 8.3393 6.23977C8.44244 6.33762 8.50039 6.47032 8.50039 6.6087V11.4783C8.49849 11.6161 8.43994 11.7477 8.3372 11.8452C8.23446 11.9427 8.09567 11.9982 7.95039 12ZM7.95039 5.3913C7.80511 5.3895 7.66632 5.33396 7.56358 5.2365C7.46085 5.13904 7.40229 5.00738 7.40039 4.86957V4.52174C7.40039 4.38337 7.45834 4.25066 7.56148 4.15281C7.66463 4.05497 7.80452 4 7.95039 4C8.09626 4 8.23615 4.05497 8.3393 4.15281C8.44244 4.25066 8.50039 4.38337 8.50039 4.52174V4.86957C8.49849 5.00738 8.43994 5.13904 8.3372 5.2365C8.23446 5.33396 8.09567 5.3895 7.95039 5.3913Z'
        fill='white'
      />
    </svg>
  )
}
