import { formatDateForUI } from '~/shared/lib/date'
import { ChevronRightIcon } from '~/shared/ui/icons'
// import { myPalette } from '~/shared/ui/theme'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Title, LinkWrapper, Descriptions, Wrapper, IonBadge } from './styled'

type CardProps = {
  id: UniqueId
  finishedAt: string
  isViolations: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Card({ id, finishedAt, isViolations }: CardProps) {
  return (
    <LinkWrapper to={id}>
      <Wrapper>
        <div>
          <Title>Осмотр {formatDateForUI(finishedAt)}</Title>
          {/*<Descriptions>*/}
          {/*  <IonBadge*/}
          {/*    style={{*/}
          {/*      '--background': isViolations*/}
          {/*        ? myPalette.coral.main*/}
          {/*        : myPalette.limeGreen['600'],*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  {isViolations ? 'Пройден с нарушениями' : 'Пройден без нарушений'}*/}
          {/*</Descriptions>*/}
        </div>
        <ChevronRightIcon />
      </Wrapper>
    </LinkWrapper>
  )
}
