import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import {
  PayCard,
  AxiosErrorType,
  PayCardAttributes,
  Payout,
  PayoutCommission,
  PayoutCan,
  AvailableFunds,
} from '~/shared/api'
import { SELECTED_PAY_CARD_BANK_CARD_ID } from '~/shared/config/constants'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { notificationModalOpen } from '~/shared/lib/notificationModal'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { storageGet, storageSet } from '~/shared/lib/storage'
import { mainBalance } from '../MainBalance'
import { confirmWithdrawalModel } from './ui/ConfirmWithdrawalDrawer'
import { withdrawalModel } from './ui/WithdrawalDrawer'

const domain = createDomain('entities.balance')
export const Gate = createGate()

// Pay cards
export const getPayCardsFx = domain.createEffect<void, PayCard[]>({
  async handler() {
    return PayCard.list()
  },
})

export const $payCards = domain
  .createStore<PayCard[] | null>(null)
  .on(getPayCardsFx.doneData, (_, payCards) => payCards)

sample({
  clock: Gate.open,
  target: getPayCardsFx,
})

sample({
  clock: getPayCardsFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получения платежных карт!',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

// Selected pay card
export const changeSelectedPayCard = domain.createEvent<UniqueId>()
const changeSelectedPayCardFx = domain.createEffect<
  {
    bankCardId: UniqueId
    payCards: PayCard[]
  },
  PayCard | undefined
>({
  handler({ bankCardId, payCards }) {
    return payCards.find((payCard) => payCard.getBankCardId() === bankCardId)
  },
})

const storageSelectedPayUpdateFx = domain.createEffect<
  PayCard | undefined,
  void
>({
  handler(payCard) {
    const bankCardId = payCard?.getBankCardId() || ''
    storageSet(SELECTED_PAY_CARD_BANK_CARD_ID, bankCardId)
  },
})

sample({
  clock: changeSelectedPayCard,
  source: $payCards,
  fn(payCards, bankCardId) {
    return { payCards: payCards || [], bankCardId }
  },
  target: changeSelectedPayCardFx,
})

sample({
  clock: changeSelectedPayCardFx.doneData,
  target: storageSelectedPayUpdateFx,
})

export const $selectedPayCard = domain
  .createStore<PayCard | null>(null)
  .on(changeSelectedPayCardFx.doneData, (_, payCard) => payCard)
  .on(getPayCardsFx.doneData, (_, payCards) => {
    const storageBankCardId = storageGet(SELECTED_PAY_CARD_BANK_CARD_ID)
    return (
      payCards.find(
        (payCard) => payCard.getBankCardId() === storageBankCardId,
      ) || null
    )
  })

// Pay card remove
export const deleteCardDrawerOpen = domain.createEvent<PayCardAttributes>()
export const deleteCardDrawerClose = domain.createEvent()
export const $payCardAttributesForDeleting = domain
  .createStore<PayCardAttributes | null>(null)
  .on(deleteCardDrawerOpen, (_, payCard) => payCard)
  .on(deleteCardDrawerClose, () => null)

export const payCardRemove = domain.createEvent()
export const payCardRemoveFx = domain.createEffect<
  UniqueId,
  void,
  AxiosErrorType
>({
  async handler(bankCardId) {
    await PayCard.remove(bankCardId)
  },
})

sample({
  clock: payCardRemove,
  source: $payCardAttributesForDeleting,
  filter: (payCard) => Boolean(payCard?.bankCardId),
  fn: (payCard) => payCard?.bankCardId as UniqueId,
  target: payCardRemoveFx,
})

sample({
  clock: payCardRemoveFx.doneData,
  source: $payCardAttributesForDeleting,
  fn(payCard) {
    return {
      title: 'Удаление краты',
      description: `Карта ${payCard?.maskedNumber} успешно удалена`,
      variant: 'success' as const,
    }
  },
  target: [notificationModalOpen, deleteCardDrawerClose, getPayCardsFx],
})

sample({
  clock: payCardRemoveFx.failData,
  fn(e) {
    logger.error(e)
    return {
      title: 'Ошибка удаления карты!',
      description: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: notificationModalOpen,
})

// Get add link
export const getAddLinkFx = domain.createEffect<void, string>({
  async handler() {
    return await PayCard.getAddLink()
  },
})

export const $addLink = domain
  .createStore<string | null>(null)
  .on(getAddLinkFx.doneData, (_, link) => link)

sample({
  clock: Gate.open,
  target: getAddLinkFx,
})

sample({
  clock: getAddLinkFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получение ссылки для добавления карты!',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

// Commission
export const setCommission = domain.createEvent<PayoutCommission | null>()

export const $commission = domain
  .createStore<PayoutCommission | null>(null)
  .on(setCommission, (_, commission) => commission)
  .on([withdrawalModel.closeWithdrawalDrawer], () => null)

// Transaction can
export const transactionCan = domain.createEvent()
export const transactionCanFx = domain.createEffect<
  number,
  PayoutCan,
  AxiosErrorType
>({
  async handler(amountTotal) {
    return await Payout.can(amountTotal)
  },
})

sample({
  clock: transactionCan,
  source: $commission,
  filter: (commission) => Boolean(commission?.amountTotal),
  fn: (commission) => commission?.amountTotal as number,
  target: transactionCanFx,
})

sample({
  clock: transactionCanFx.doneData,
  filter: (res) => res.result,
  target: confirmWithdrawalModel.openConfirmWithdrawalDrawer,
})

sample({
  clock: transactionCanFx.doneData,
  filter: (res) => !res.result,
  fn(res) {
    return {
      description: res.message,
      variant: 'error' as const,
    }
  },
  target: notificationModalOpen,
})

sample({
  clock: transactionCanFx.failData,
  fn(e) {
    return {
      title: 'Ошибка сервиса!',
      description: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: notificationModalOpen,
})

// Payouts
export const payout = domain.createEvent()
export const payoutFx = domain.createEffect<
  {
    amount: number
    bankCardId: string
  },
  void
>({
  async handler(values) {
    const payout = new Payout(values)
    await payout.save()
  },
})

sample({
  clock: payout,
  source: $commission,
  fn: (commission) => {
    return {
      amount: commission?.amount as number,
      bankCardId: commission?.bankCardId as UniqueId,
    }
  },
  target: payoutFx,
})

sample({
  clock: payoutFx.doneData,
  source: $commission,
  fn(commission) {
    return {
      title: `Вы получите ${commission?.amount} руб.`,
      variant: 'success' as const,
    }
  },
  target: [
    notificationModalOpen,
    withdrawalModel.closeWithdrawalDrawer,
    confirmWithdrawalModel.closeConfirmWithdrawalDrawer,
    mainBalance.getCurrentBalanceSilentFx,
  ],
})

sample({
  clock: payoutFx.failData,
  fn(e) {
    logger.error(e)
    return {
      title: 'Не удалось вывести средства',
      description:
        'Возможны проблемы на стороне банка или таксопарка, попробуйте выбрать карту другого банка или вывести средства позже',
      variant: 'error' as const,
    }
  },
  target: notificationModalOpen,
})

// Available funds
export const getAvailableFundsFx = domain.createEffect<
  { bankCardId: UniqueId },
  AvailableFunds
>({
  async handler({ bankCardId }) {
    return Payout.availableFunds(bankCardId)
  },
})

export const $availableFunds = domain
  .createStore<AvailableFunds | null>(null)
  .on(getAvailableFundsFx.doneData, (_, availableFunds) => availableFunds)

sample({
  clock: $selectedPayCard,
  filter: (selectedPayCard) => Boolean(selectedPayCard),
  fn(selectedPayCard) {
    return { bankCardId: selectedPayCard?.getBankCardId() }
  },
  target: getAvailableFundsFx,
})

sample({
  clock: getAvailableFundsFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получения средств для вывода!',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})
