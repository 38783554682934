import { Unstable_Grid2 as Grid, styled } from '@mui/material'

export const GridItem = styled(Grid)`
  cursor: pointer;
  transition: 0.15s ease opacity;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.5;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`
