import { useStoreMap } from 'effector-react'
import { supportModel } from '~/widgets/Support'
import { Content } from '~/widgets/Support/ui/styled'
import { SupportEnum } from '~/shared/config/enums'
import { DrawerComponent } from '~/shared/ui/Drawer'

type CarNotStartDrawerProps = {
  isShow: boolean
  onClose: () => void
}

export function CarNotStartDrawer({ isShow, onClose }: CarNotStartDrawerProps) {
  const [support] = useStoreMap(supportModel.$supports, (support) =>
    (support || [])?.map((support) => ({
      ...support.getAttributes(),
      id: support.getApiId() as string,
    })),
  ).filter(({ position }) => position === SupportEnum.CAR_NOT_START)

  return (
    <DrawerComponent isShow={isShow} onCancel={onClose}>
      <Content
        dangerouslySetInnerHTML={{
          __html: support?.content || 'Контент не найден',
        }}
      />
    </DrawerComponent>
  )
}
