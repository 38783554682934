import { createDomain, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { interval } from 'patronum'
import {
  Acceptance,
  AcceptanceAttributes,
  AcceptFetchValues,
  AxiosErrorType,
  ConfirmFetchValues,
} from '~/shared/api'
import { AcceptanceDocumentTypeEnum } from '~/shared/config/enums'
import { isDevEnv } from '~/shared/config/env'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const getAcceptanceByType = (
  acceptances: AcceptanceAttributes[],
  documentType: AcceptanceDocumentTypeEnum,
) => {
  return (
    acceptances?.find(
      (acceptance) => acceptance.documentType === documentType,
    ) || null
  )
}

export const Gate = createGate()

const domain = createDomain('widgets.proposal')

const getNeed = domain.createEvent()
export const getNeedFx = domain.createEffect({
  async handler() {
    return await Acceptance.need()
  },
})

export const getNeedSilentFx = domain.createEffect({
  async handler() {
    return await Acceptance.need()
  },
})

export const $acceptanceNeedPending = createStore<boolean>(true).on(
  [getNeedFx.doneData, getNeedFx.failData],
  () => false,
)

export const driverPersonalDataAcceptanceDelete = domain.createEvent()
export const $driverPersonalDataAcceptance =
  createStore<AcceptanceAttributes | null>(null)
    .on([getNeedFx.doneData, getNeedSilentFx.doneData], (_, acceptances) =>
      getAcceptanceByType(
        acceptances,
        AcceptanceDocumentTypeEnum.DriverPersonalData,
      ),
    )
    .on([Gate.close, driverPersonalDataAcceptanceDelete], () => null)

export const publicOfferAcceptanceDelete = domain.createEvent()
export const $publicOfferAcceptance = createStore<AcceptanceAttributes | null>(
  null,
)
  .on([getNeedFx.doneData, getNeedSilentFx.doneData], (_, acceptances) =>
    getAcceptanceByType(acceptances, AcceptanceDocumentTypeEnum.PublicOffer),
  )
  .on([Gate.close, publicOfferAcceptanceDelete], () => null)

sample({
  clock: Gate.open,
  target: getNeed,
})

sample({
  clock: getNeed,
  target: getNeedFx,
})

sample({
  clock: getNeedFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получения данных согласия',
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

export const acceptFx = domain.createEffect<
  AcceptFetchValues,
  { id: UniqueId },
  AxiosErrorType
>({
  async handler(values) {
    return await Acceptance.accept(values)
  },
})

sample({
  clock: acceptFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

export const confirmFx = domain.createEffect<
  ConfirmFetchValues,
  void,
  AxiosErrorType
>({
  async handler(values) {
    return await Acceptance.confirm(values)
  },
})

sample({
  clock: confirmFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'danger' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $acceptanceId = createStore<UniqueId | null>(null)
  .on(acceptFx.doneData, (_, accept) => accept.id)
  .on(confirmFx.doneData, () => null)

const intervalRequestStarted = domain.createEvent()
const intervalRequestStopped = domain.createEvent()

if (!isDevEnv) {
  const { tick } = interval({
    timeout: 60000,
    start: intervalRequestStarted,
    stop: intervalRequestStopped,
    leading: false,
    trailing: false,
  })

  sample({
    clock: tick,
    source: [$driverPersonalDataAcceptance, $publicOfferAcceptance],
    filter: ([personalData, publicOffer]) => !personalData && !publicOffer,
    target: getNeedSilentFx,
  })
  sample({
    clock: Gate.open,
    target: intervalRequestStarted,
  })
  sample({
    clock: Gate.close,
    target: intervalRequestStopped,
  })
}
