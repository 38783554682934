export function RubGrayIcon() {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.78743 7.82943H9.8724C11.3764 7.82943 12.5957 6.61016 12.5957 5.10612C12.5957 3.60208 11.3764 2.38281 9.8724 2.38281H5.78743V7.82943ZM5.78743 7.82943H4.42578M9.19157 10.5527H4.42578M5.78743 2.72323V13.9569'
        stroke='#B2BDD6'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
