import { MarkerComponentProps } from 'react-image-marker'
import { PolygonMarker } from '~/shared/ui/Polygon/types'
import { Wrapper } from './styled'

type MarkerProps = MarkerComponentProps & PolygonMarker

export function Marker({ level, color }: MarkerProps) {
  const style = color ? { background: color } : {}

  return <Wrapper sx={{ ...style }}>{level}</Wrapper>
}
