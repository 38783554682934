import { CheckItem } from './CheckItem'
import { CheckTypesEnum } from './enum'
import { Wrapper } from './styled'

type ListEquipmentsProps = {
  checkItems: { type: CheckTypesEnum; label: string }[]
}

export function CheckItemList({ checkItems }: ListEquipmentsProps) {
  return (
    <Wrapper>
      {checkItems.map(({ type, label }, index) => (
        <CheckItem key={`${index}_${label}`} type={type}>
          {label}
        </CheckItem>
      ))}
    </Wrapper>
  )
}
