import { Wrapper, Chip, Img, IsLeasingFullyPaidChip } from './styled'

type PhotosProps = {
  plateNumber: string
  photos: string[]
  isLeasingFullyPaid: boolean
}

export function Photos({
  plateNumber,
  photos,
  isLeasingFullyPaid,
}: PhotosProps) {
  const photo = photos?.[0]
  return (
    <Wrapper>
      <Img src={photo} alt='booking-car-photo' />
      <Chip label={plateNumber} variant='outlined' size='small' />
      {isLeasingFullyPaid && (
        <IsLeasingFullyPaidChip
          label='Возможен выкуп'
          variant='outlined'
          size='small'
        />
      )}
    </Wrapper>
  )
}
