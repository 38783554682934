import { useMainTaxiparks } from '~/widgets/MainTaxipark'
import { InspectionsIcon } from '~/shared/ui/icons'
import { Wrapper, Title, Descriptions, ImageWrapper, Link } from './styled'

export function GoToInspectionsButton() {
  const isInspectionsVisible = useMainTaxiparks()?.isInspectionsVisible

  if (!isInspectionsVisible) return null

  return (
    <Link to='/inspections'>
      <Wrapper>
        <ImageWrapper>
          <InspectionsIcon />
        </ImageWrapper>
        <div>
          <Title>Осмотры</Title>
          <Descriptions>Информация о последних смотрах</Descriptions>
        </div>
      </Wrapper>
    </Link>
  )
}
