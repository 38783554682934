import { useGate, useStoreMap } from 'effector-react'
import { useParams } from 'react-router-dom'
import {
  LoadingBlock,
  BookingCar,
  bookingCarModel,
} from '~/features/BookingCars/BookingCar'
import { entityBookingCarModel } from '~/entities/BookingCar'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { NotFound } from '~/shared/ui/NotFound'
import { PageContainer } from '~/shared/ui/PageContainer'

export const BookingCarPage = () => {
  const params = useParams<{ bookingCarId: UniqueId }>()
  const paramsBookingCarId = params.bookingCarId as UniqueId

  useGate(bookingCarModel.Gate, { id: paramsBookingCarId })

  const bookingCarId = useStoreMap(bookingCarModel.$bookingCar, (bookingCar) =>
    bookingCar?.getApiId(),
  )
  const bookingCarTitle = useStoreMap(
    bookingCarModel.$bookingCar,
    (bookingCar) => `${bookingCar?.getBrand()} ${bookingCar?.getModel()}`,
  )
  const bookingCarLoaded = bookingCarId === paramsBookingCarId
  const title = `${bookingCarLoaded ? bookingCarTitle : ''}`

  const displayName = bookingCarLoaded ? (
    bookingCarTitle
  ) : (
    <ProgressBlock height={20} size={16} />
  )

  const bookingCarError =
    entityBookingCarModel.useBookingCarError(paramsBookingCarId)
  if (bookingCarError) {
    return <NotFound message='Не удалось загрузить автомобиль' />
  }

  return (
    <PageContainer title={title} backTitle={displayName} canBack>
      {bookingCarLoaded ? <BookingCar /> : <LoadingBlock />}
    </PageContainer>
  )
}
