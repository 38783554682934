import * as z from 'zod'
import { Location } from '~/shared/api'
import { dateSchema, uuidOptionSchema } from '~/shared/lib/schemas'
import { FuelTypeEnum, TransmissionEnum } from '../config/enums'
import { ApiModel, ToOneRelation } from './core'

const attributesSchema = z.object({
  manufactureYear: z.string(),
  color: z.string(),
  fuelType: z.nativeEnum(FuelTypeEnum),
  brand: z.string(),
  model: z.string(),
  plateNumber: z.string(),
  pricePerDay: z.number(),
  transmission: z.nativeEnum(TransmissionEnum),
  vehicleCategory: z.string(),
  photos: z.array(z.string()),
  isLeasingFullyPaid: z.boolean().optional(),
  mileage: z.number(),
  cancellationFeeRub: z.number().nullable(),
  cancellationFeePercentage: z.number(),
})

const relationsSchema = z.object({
  locationId: uuidOptionSchema,
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)
  .merge(relationsSchema)

export type BookingCarAttributes = z.infer<typeof attributesSchema>

export class BookingCar extends ApiModel<typeof schema, BookingCarAttributes> {
  static jsonApiType = 'booking-cars'

  static schema = schema

  getModel(): string {
    return this.getAttribute('model')
  }
  getBrand(): string {
    return this.getAttribute('brand')
  }
  getPlateNumber(): string {
    return this.getAttribute('plateNumber')
  }
  getPricePerDay(): number {
    return this.getAttribute('pricePerDay')
  }
  getPhotos(): string[] {
    return this.getAttribute('photos')
  }
  getIsLeasingFullyPaid(): boolean {
    return this.getAttribute('isLeasingFullyPaid')
  }
  getCancellationFeeRub(): number | null {
    return this.getAttribute('cancellationFeeRub')
  }

  location(): ToOneRelation<Location, this> {
    return this.hasOne(Location)
  }
  getLocation(): Location {
    return this.getRelation('location')
  }

  static async getPrices(
    regionId: UniqueId,
    locationIds: string[],
  ): Promise<{ min: string | null; max: string | null }> {
    const locationsFilter = locationIds?.length
      ? `&locationIds=${locationIds}`
      : ''

    const url = `${BookingCar.getJsonApiUrl()}/actions/prices?regionId=${regionId}${locationsFilter}`
    const client = BookingCar.httpClient.getImplementingClient()
    const { data } = await client.get<{
      data: {
        minPrice: string | null
        maxPrice: string | null
      }
    }>(url)

    return {
      min: data?.data?.minPrice || null,
      max: data?.data?.maxPrice || null,
    }
  }

  static async getModels(regionId: UniqueId, locationIds: string[]) {
    const locationsFilter = locationIds?.length
      ? `&locationIds=${locationIds}`
      : ''

    const url = `${BookingCar.getJsonApiUrl()}/actions/models?regionId=${regionId}${locationsFilter}`
    const client = BookingCar.httpClient.getImplementingClient()
    const { data } = await client.get<{ data: Record<string, string[]> }>(url)

    return data?.data
  }
}
