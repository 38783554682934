import * as z from 'zod'
import { BookingCar } from '~/shared/api/bookingCar'
import { RentalContractStatusEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel, ToOneRelation } from './core'

const attributesSchema = z.object({
  bookedUntil: dateSchema.optional().nullable(),
  contractNumber: z.string(),
  firstDayIsFree: z.boolean(),
  rentPerDay: z.string(),
  workRuleTitle: z.string(),
  workSchedule: z.string(),
  status: z.nativeEnum(RentalContractStatusEnum),
  cancellationFeeRub: z.number().nullable(),
  cancellationFeePercentage: z.number(),
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type RentalContactAttributes = z.infer<typeof attributesSchema>

export class RentalContact extends ApiModel<
  typeof schema,
  RentalContactAttributes
> {
  static jsonApiType = 'rental-contracts'

  static schema = schema

  getCarImage(): string | undefined {
    return this.getAttribute('carImage')
  }
  getStatus(): RentalContractStatusEnum {
    return this.getAttribute('status')
  }
  getBookedUntil(): string {
    return this.getAttribute('bookedUntil')
  }
  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }
  getCancellationFeeRub(): number | null {
    return this.getAttribute('cancellationFeeRub')
  }

  car(): ToOneRelation<BookingCar, this> {
    return this.hasOne(BookingCar)
  }
  getCar(): BookingCar {
    return this.getRelation('car')
  }

  static async my() {
    const response = await RentalContact.with('car')
      .with('car.location')
      .find('my')
    return response?.getData() as RentalContact
  }

  static async file(): Promise<Blob> {
    const url = `${RentalContact.getJsonApiUrl()}/file`
    const client = RentalContact.httpClient.getImplementingClient()

    const response = await client.get(url, {
      responseType: 'blob',
    })

    return response?.data
  }

  static async confirmFile() {
    const url = `${RentalContact.getJsonApiUrl()}/confirm-file`
    const client = RentalContact.httpClient.getImplementingClient()

    const response = await client.get(url, {
      responseType: 'blob',
    })

    return response?.data
  }

  static async confirmCode(): Promise<{ message: string }> {
    const url = `${RentalContact.getJsonApiUrl()}/confirm-code`
    const client = RentalContact.httpClient.getImplementingClient()

    const response = await client.post(url)

    return response?.data
  }

  static async confirm(confirmCode: string) {
    const url = `${RentalContact.getJsonApiUrl()}/confirm`
    const client = RentalContact.httpClient.getImplementingClient()

    return await client.post(url, { confirmCode })
  }
}
