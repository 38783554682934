export function AddressGrayIcon() {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.40381 9.72621C2.14335 10.2823 1.36133 11.0571 1.36133 11.9141C1.36133 13.6062 4.40949 14.9779 8.1696 14.9779C11.9297 14.9779 14.9779 13.6062 14.9779 11.9141C14.9779 11.0571 14.1958 10.2823 12.9354 9.72621M12.2546 5.44629C12.2546 8.21297 9.19084 9.53125 8.1696 11.5737C7.14836 9.53125 4.08464 8.21297 4.08464 5.44629C4.08464 3.19023 5.91353 1.36133 8.1696 1.36133C10.4257 1.36133 12.2546 3.19023 12.2546 5.44629ZM8.85042 5.44629C8.85042 5.8223 8.54561 6.12712 8.1696 6.12712C7.79359 6.12712 7.48877 5.8223 7.48877 5.44629C7.48877 5.07028 7.79359 4.76546 8.1696 4.76546C8.54561 4.76546 8.85042 5.07028 8.85042 5.44629Z'
        stroke='#B2BDD6'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
