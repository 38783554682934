import { IonHeader, IonPage } from '@ionic/react'
import { Helmet } from 'react-helmet-async'
import { RentalContractSigning } from '~/features/RentalContractSigning'
import { ButtonBack } from '~/shared/ui/ButtonBack'
import { myPalette } from '~/shared/ui/theme'
import { Content, IonContent, IonToolbar, Title, WrapperHeader } from './styled'

export function RentalContractSigningPage() {
  return (
    <IonPage
      style={{
        background: myPalette.midnightBlue.main,
      }}
    >
      <Helmet>
        <title>Договор на подписание</title>
      </Helmet>
      <IonHeader>
        <IonToolbar>
          <WrapperHeader>
            <ButtonBack
              showIcon={false}
              sx={{
                color: myPalette.midnightBlue['700'],
                padding: 0,
                position: 'relative',
                zIndex: 1,
              }}
            />
            <Title color='dark.main' fontSize={16}>
              Договор на подписание
            </Title>
          </WrapperHeader>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Content>
          <RentalContractSigning />
        </Content>
      </IonContent>
    </IonPage>
  )
}
