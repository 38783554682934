import { Box, Typography } from '@mui/material'
import { useCallback } from 'react'
import { Inspection } from '~/shared/api'
import { FetchFn, ScrollableList, Item } from '~/shared/ui/ScrollableList'
import { Card } from './ui/Card'

type Row = Item & { finishedAt: string; isViolations: boolean }

export function ListInspections() {
  const fetchRows = useCallback<FetchFn<Row>>(async () => {
    const data = await Inspection.getLastInspections()

    const items = data.map((inspection) => {
      return {
        id: inspection?.getApiId() as string,
        finishedAt: inspection?.getFinishedAt(),
        isViolations: Boolean(inspection?.getViolations()?.length),
      }
    })

    return {
      items,
      to: items?.length ? 1 : 0,
      currentPage: 1,
      lastPage: 1,
    }
  }, [])

  const handleMapItems = useCallback(
    (items: Row[]) => (
      <>
        {items.map(({ id, finishedAt, isViolations }) => (
          <Box mb='8px' key={id}>
            <Card id={id} finishedAt={finishedAt} isViolations={isViolations} />
          </Box>
        ))}
      </>
    ),
    [],
  )

  return (
    <>
      <ScrollableList
        queryKey='list-inspections'
        fetchFn={fetchRows}
        mapItems={handleMapItems}
        skeletonItemMarginBottom='8px'
        skeletonHeight={81}
        empty={
          <Typography fontSize='18' mt='40px'>
            По текущему автомобилю осмотры не найдены
          </Typography>
        }
      />
    </>
  )
}
