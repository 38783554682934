import EditNoteIcon from '@mui/icons-material/EditNote'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import { Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { closeDocumentsDrawer } from '~/widgets/Documents/model'
import { rentalContractModel } from '~/entities/RentalContract'
import { RentalContractStatusEnum } from '~/shared/config/enums'
import { formatDateForUI } from '~/shared/lib/date'
import { pushFx } from '~/shared/lib/history'
import { myPalette } from '~/shared/ui/theme'
import { Card, TitleWrapper } from '../styled'
import {
  Gate,
  loadRentalContractConfirmFile,
  loadRentalContractConfirmFileFx,
  loadRentalContractFile,
  loadRentalContractFileFx,
} from './model'
import {
  ButtonSigningWrapper,
  ButtonWrapper,
  LoadingButton,
  Skeleton,
  WaitingSigningTitle,
} from './styled'

export function RentalContract() {
  useGate(Gate)

  const isFetchRentalContractPending = useStore(
    rentalContractModel.getRentalContractFx.pending,
  )
  const rentalContract = useStore(rentalContractModel.$rentalContract)
  const isRentalContractFilePending = useStore(loadRentalContractFileFx.pending)
  const isRentalContractConfirmFilePending = useStore(
    loadRentalContractConfirmFileFx.pending,
  )

  const createdAd = rentalContract?.getCreatedAt()
  const status = rentalContract?.getStatus()

  const isSigning = status === RentalContractStatusEnum.SIGNING
  const isSigned = status === RentalContractStatusEnum.SIGNED
  const isActive = status === RentalContractStatusEnum.ACTIVE
  const isSuspended = status === RentalContractStatusEnum.SUSPENDED

  const handleGoToSigning = () => {
    closeDocumentsDrawer()
    pushFx('/rental-contract-signing')
  }

  return (
    <Card>
      <TitleWrapper>
        <Typography fontSize={18} fontWeight={500}>
          Договор
        </Typography>

        {createdAd && (
          <Typography fontSize={14} color='mutedText'>
            От {formatDateForUI(createdAd)}
          </Typography>
        )}
      </TitleWrapper>

      {!isFetchRentalContractPending && !rentalContract && (
        <Typography>Договора нет</Typography>
      )}

      {isFetchRentalContractPending && <Skeleton />}

      {!isFetchRentalContractPending && rentalContract && (
        <>
          <ButtonSigningWrapper>
            {(isSigning || isSigned || isActive) && (
              <WaitingSigningTitle
                fontSize={14}
                color='mutedText'
                chipColor={
                  isSigning ? myPalette.coral.main : myPalette.limeGreen.main
                }
              >
                {isSigning && 'Ожидает подписания'}
                {isSigned && 'Подписан'}
                {isActive && 'Активный'}
                {isSuspended && 'Приостановлен'}
              </WaitingSigningTitle>
            )}

            <ButtonWrapper>
              {isSigning && (
                <LoadingButton
                  variant='limeFlooded'
                  size='small'
                  onClick={handleGoToSigning}
                >
                  Подписать
                </LoadingButton>
              )}

              {(isSigned || isActive || isSuspended) && (
                <LoadingButton
                  variant='lightSteelBlueBorder'
                  size='small'
                  onClick={() => loadRentalContractConfirmFile()}
                  loading={isRentalContractConfirmFilePending}
                  endIcon={<EditNoteIcon />}
                >
                  Подпись
                </LoadingButton>
              )}

              <LoadingButton
                variant='limeFlooded'
                size='small'
                onClick={() => loadRentalContractFile()}
                loading={isRentalContractFilePending}
                endIcon={<FileOpenIcon />}
              >
                Договор
              </LoadingButton>
            </ButtonWrapper>
          </ButtonSigningWrapper>
        </>
      )}
    </Card>
  )
}
