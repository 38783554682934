import { combine, createDomain, sample } from 'effector'
import { createGate, useStoreMap } from 'effector-react'
import { Inspection } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('entities.inspection')
export const Gate = createGate<{ id: UniqueId }>()

type InspectionsObj = {
  id: UniqueId
  inspection: Inspection
  previousInspection: Inspection | null
}

export const requestFx = domain.createEffect<UniqueId, InspectionsObj>({
  handler: fetchInspection,
})

const {
  $cache: $inspectionCache,
  useCache: useInspectionCache,
  updateCache,
} = createCache<InspectionsObj>({
  domain,
  getEntityId: (inspection) => inspection.id as UniqueId,
})
export { $inspectionCache, useInspectionCache }

$inspectionCache.on(requestFx.doneData, (cache, inspection) =>
  updateCache(cache, [inspection]),
)

export const $inspectionError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(requestFx.fail, (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useInspectionError = (id: UniqueId) =>
  useStoreMap($inspectionError, (errors) => errors[id])

async function fetchInspection(id: UniqueId) {
  const inspectionRes = await Inspection.find(id)
  const previousInspectionRes = await Inspection.getPreviousInspection(id)
  return {
    id,
    inspection: inspectionRes.getData() as Inspection,
    previousInspection: previousInspectionRes,
  }
}

export const $id = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { id }) => id)

export const $inspection = combine($id, $inspectionCache, (id, cache) => {
  if (!id) return null
  return cache.map[id]?.inspection ?? null
})

export const $previousInspection = combine(
  $id,
  $inspectionCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id]?.previousInspection ?? null
  },
)

sample({
  clock: $id,
  filter: isString,
  target: requestFx,
})
