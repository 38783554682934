import { Typography } from '@mui/material'
import { useStore } from 'effector-react'
import { ListInspections } from '~/features/Inspections/ListInspections'
import { rentalContractModel } from '~/entities/RentalContract'
import { PageContainer } from '~/shared/ui/PageContainer'
import { SkeletonList } from '~/shared/ui/SkeletonList'

export function ListInspectionsPage() {
  const rentalContract = useStore(rentalContractModel.$rentalContract)
  const rentalContractPending = useStore(
    rentalContractModel.getRentalContractFx.pending,
  )

  return (
    <PageContainer
      title='Осмотры'
      backTitle='Осмотры'
      canBack
      preventScrollReset
    >
      {rentalContractPending && (
        <SkeletonList count={10} skeletonHeight={81} itemMarginBottom='8px' />
      )}

      {!rentalContractPending && rentalContract && <ListInspections />}
      {!rentalContractPending && !rentalContract && (
        <Typography fontSize='18' mt='40px'>
          Для просмотра осмотров у вас должен быть действующий договор аренды!
        </Typography>
      )}
    </PageContainer>
  )
}
