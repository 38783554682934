import { Box } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { Outlet } from 'react-router-dom'
import { useTaxiparkKeyQuery } from '~/entities/BookingCar'
import { ProgressBlock } from '~/shared/ui/Loaders'
import { NotFound } from '~/shared/ui/NotFound'
import { $interceptorId, Gate } from './model'

export function ProtectedBookingCarsPage() {
  const xTaxiparkKeyQuery = useTaxiparkKeyQuery()

  useGate(Gate, { xTaxiparkKey: xTaxiparkKeyQuery })

  const interceptorId = useStore($interceptorId)

  if (!xTaxiparkKeyQuery)
    return <NotFound message='Не корректная сылка каталога' />

  if (!interceptorId)
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ProgressBlock />
      </Box>
    )

  return <Outlet />
}
