import { Typography } from '@mui/material'
import { Damage } from '~/entities/Inspection'
import { InspectionDamageAttributes } from '~/shared/api'
import { Wrapper } from './styled'

type DamagesProps = {
  damages?: InspectionDamageAttributes[]
}

export function Damages({ damages }: DamagesProps) {
  if (!damages?.length)
    return <Typography fontSize='18'>Повреждений нет</Typography>

  return (
    <Wrapper>
      {(damages || []).map((damage, index) => (
        <Damage
          isDetailLabelVisible
          key={index}
          detailTitle={damage.detailTitle}
          extentTitle={damage.extentTitle}
          cost={damage.cost}
          photos={damage.photos}
        />
      ))}
    </Wrapper>
  )
}
