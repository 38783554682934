import { StringParam, useQueryParam, withDefault } from 'use-query-params'

export const useTaxiparkKeyQuery = () => {
  const [xTaxiparkKeyQuery] = useQueryParam(
    'xTaxiparkKey',
    withDefault(StringParam, ''),
  )

  return xTaxiparkKeyQuery
}
