import {
  IonAccordion as IonicIonAccordion,
  IonItem as IonicIonItem,
  IonBadge as IonicIonBadge,
} from '@ionic/react'
import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const IonAccordion = styled(IonicIonAccordion)`
  background: transparent;
  border-bottom: 2px solid ${myPalette.blue.lightGrayishBlue};
`

export const IonItem = styled(IonicIonItem)`
  --background: transparent;
  --background-hover: transparent;
  --border-width: 0;

  &::part(native) {
    --padding-start: 0;
  }

  ion-icon {
    position: absolute;
    right: 0;
  }
`

export const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 72px;
`

export const Title = styled('div')`
  font-size: 18px;
  font-weight: 700;
  margin-right: 8px;
`

export const IonBadge = styled(IonicIonBadge)`
  --background: ${myPalette.coral.main};
  --color: ${myPalette.white.main};
  min-width: 18px;
  height: 18px;
  padding: 3px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
`

export const Content = styled('div')`
  margin-bottom: 24px;
`
