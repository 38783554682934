export enum TransmissionEnum {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum FuelTypeEnum {
  AI100 = 'AI100',
  AI92 = 'AI92',
  AI95 = 'AI95',
  AI98 = 'AI98',
  DIESEL = 'DIESEL',
  METHANE = 'METHANE',
  PROPANE = 'PROPANE',
  ELECTRICITY = 'ELECTRICITY',
}

export enum CarTariffEnum {
  ECONOMY = 'ECONOMY',
  BUSINESS = 'BUSINESS',
}

export enum RentalContractWorkScheduleEnum {
  FULL_WEEK = '7/0',
  SIX_THROUGH_ONE = '6/1',
}

export enum RentalStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
}

export enum AcceptanceDocumentTypeEnum {
  DriverPersonalData = 'DriverPersonalData',
  PublicOffer = 'PublicOffer',
}

export enum MoveFundsType {
  COMING = 'COMING',
  SPENDING = 'SPENDING',
}

export enum RentalContractStatusEnum {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  SIGNING = 'SIGNING',
  SIGNED = 'SIGNED',
}

export enum BannerPositionEnum {
  INFORMATION = 'information',
}

export enum SupportEnum {
  ADDRESS_AND_CONTACTS = 'address_and_contacts',
  CAR_NOT_START = 'car_not_start',
  BREAKDOWN_ON_ROAD = 'breakdown_on_road',
  DOCUMENTS = 'documents',
  TRAFFIC_POLICE_FINES = 'traffic_police_fines',
  EUROPEAN_PROTOCOL = 'european_protocol',
  ROAD_ACCIDENT = 'road_accident',
}

export enum ContactMethodEnum {
  phone = 'phone',
  telegram = 'telegram',
}

export enum DayEnum {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum CarEquipmentTypeEnum {
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  TRUNK = 'TRUNK',
}

export enum DocumentsTypeEnum {
  STS = 'STS',
  OSAGO = 'OSAGO',
  DC = 'DC',
  LICENSE = 'LICENSE',
}

export enum CarDetailTypeEnum {
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  TIRE = 'TIRE',
  ENVIRONMENT = 'ENVIRONMENT',
}

export enum CarDamageExtendsCodeEnum {
  LIGHT = 'LIGHT',
  MEDIUM = 'MEDIUM',
  HEAVY = 'HEAVY',
}

export enum InspectionPhotoTypeEnum {
  CAR = 'car',
  TARIFFS = 'tariffs',
  LAST_TO = 'lastTO',
  MILEAGE = 'mileage',
  DAMAGE = 'damage',
  DRIVER_SIGNATURE = 'driverSignature',
}
