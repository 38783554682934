import { Box, Typography } from '@mui/material'
import { useIsFetching, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useTaxiparkKeyQuery } from '~/entities/BookingCar'
import { BookingCar, BookingCarAttributes } from '~/shared/api'
import {
  FetchFn,
  SCROLLABLE_LIST_META_PAGE,
  ScrollableList,
  Item,
  Sort,
} from '~/shared/ui/ScrollableList'
import { SkeletonList } from '~/shared/ui/SkeletonList'
import { MAIN_REGIONS_OPTIONS_QUERY_KEY, sortConfig } from './config'
import { ActionButtonsWrapper } from './styled'
import { Card } from './ui/Card'
import { Filters } from './ui/Filters'
import { Skeleton } from './ui/Skeleton'
import { useExternalFilters } from './useExternalFilters'

type Row = Item & BookingCarAttributes

export function ListBookingCars() {
  const xTaxiparkKeyQuery = useTaxiparkKeyQuery()
  const { externalFilters, regionId } = useExternalFilters()

  const queryClient = useQueryClient()

  const mainRegionsOptionsIsFetching = useIsFetching([
    MAIN_REGIONS_OPTIONS_QUERY_KEY,
  ])
  const mainRegionsOptionsQueryState = queryClient.getQueryState([
    MAIN_REGIONS_OPTIONS_QUERY_KEY,
  ])
  const hasMainRegionsOptionsError =
    mainRegionsOptionsQueryState?.status === 'error'

  const fetchRows = useCallback<FetchFn<Row>>(
    async (page, pageSize, filters, sort) => {
      let builder = BookingCar.limit(pageSize)

      sort && (builder = builder.orderBy(sort))

      filters?.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)
      const data = response.getData()

      const items = data.map((bookingCar) => {
        return {
          id: bookingCar?.getApiId() as string,
          ...bookingCar.getAttributes(),
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const { to, currentPage, lastPage } =
        axiosResponse?.data?.meta?.page || SCROLLABLE_LIST_META_PAGE

      return {
        items,
        to,
        currentPage,
        lastPage,
      }
    },
    [],
  )

  const handleMapItems = useCallback(
    (items: Row[]) => (
      <>
        {items.map(({ id, ...attr }) => (
          <Box mb='24px' key={id}>
            <Card
              carId={id}
              carAttributes={attr}
              xTaxiparkKey={xTaxiparkKeyQuery}
            />
          </Box>
        ))}
      </>
    ),
    [xTaxiparkKeyQuery],
  )

  return (
    <>
      <ActionButtonsWrapper>
        <Sort options={sortConfig} />
        <Filters />
      </ActionButtonsWrapper>

      {Boolean(mainRegionsOptionsIsFetching && !regionId) && (
        <SkeletonList
          count={10}
          skeletonNode={<Skeleton />}
          itemMarginBottom='24px'
        />
      )}

      {!mainRegionsOptionsIsFetching && hasMainRegionsOptionsError && (
        <Typography fontSize='18' mt='40px'>
          Ошибка получение региона
        </Typography>
      )}

      {Boolean(regionId) && (
        <ScrollableList
          queryKey='list-booking-cars'
          externalFilters={externalFilters}
          fetchFn={fetchRows}
          mapItems={handleMapItems}
          skeletonNode={<Skeleton />}
          skeletonItemMarginBottom='24px'
          empty={
            <Typography fontSize='18' mt='40px'>
              По выбранному региону автомобилей не найдено!
            </Typography>
          }
        />
      )}
    </>
  )
}
