export function InspectionsIcon() {
  return (
    <svg
      width='36'
      height='37'
      viewBox='0 0 36 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        y='36.9863'
        width='36'
        height='36'
        rx='18'
        transform='rotate(-90 0 36.9863)'
        fill='#021542'
      />
      <path
        d='M24.1176 13.4369C23.9294 12.8816 23.5624 12.4863 22.9412 12.4863H13.0588C12.4376 12.4863 12.08 12.8816 11.8824 13.4369L10 18.8393V25.8981C10 26.4157 10.1882 26.604 10.7059 26.604H11.1765C11.6941 26.604 11.8824 26.4157 11.8824 25.8981V24.7216H24.1176V25.8981C24.1176 26.4157 24.3059 26.604 24.8235 26.604H25.2941C25.8118 26.604 26 26.4157 26 25.8981V18.8393L24.1176 13.4369Z'
        stroke='#E4ECFF'
        strokeWidth='1.2'
      />
      <path
        d='M12.824 21.898C12.5744 21.898 12.335 21.7988 12.1585 21.6223C11.982 21.4458 11.8828 21.2064 11.8828 20.9568C11.8828 20.7072 11.982 20.4678 12.1585 20.2913C12.335 20.1148 12.5744 20.0156 12.824 20.0156C13.0736 20.0156 13.313 20.1148 13.4895 20.2913C13.666 20.4678 13.7652 20.7072 13.7652 20.9568C13.7652 21.2064 13.666 21.4458 13.4895 21.6223C13.313 21.7988 13.0736 21.898 12.824 21.898ZM23.1769 21.898C22.9273 21.898 22.6879 21.7988 22.5114 21.6223C22.3349 21.4458 22.2358 21.2064 22.2358 20.9568C22.2358 20.7072 22.3349 20.4678 22.5114 20.2913C22.6879 20.1148 22.9273 20.0156 23.1769 20.0156C23.4265 20.0156 23.6659 20.1148 23.8424 20.2913C24.0189 20.4678 24.1181 20.7072 24.1181 20.9568C24.1181 21.2064 24.0189 21.4458 23.8424 21.6223C23.6659 21.7988 23.4265 21.898 23.1769 21.898Z'
        stroke='#E4ECFF'
        strokeWidth='1.2'
      />
      <path
        d='M12.1172 17.1926L13.2937 13.8984H22.7054L23.8819 17.1926H12.1172Z'
        stroke='#E4ECFF'
        strokeWidth='1.2'
        strokeLinejoin='round'
      />
    </svg>
  )
}
