import { Button } from '@mui/material'
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Option } from '~/shared/config/constants'
import { DrawerComponent, useDrawer } from '~/shared/ui/Drawer'
import { SkeletonList } from '~/shared/ui/SkeletonList'
import { Checkbox } from './Checkbox'
import { filterOptions, groupOptionsByKey } from './helpers'
import {
  Title,
  DrawerWrapper,
  InputWrapper,
  InputIcon,
  IonInput,
  CheckboxTitle,
  ButtonsWrapper,
  CheckBoxWrapper,
} from './styled'

type AsyncSelectMultipleProps<T> = {
  field: string
  options: T[]
  label: string
  title?: string
  modalTitle: string
  gropedByKey?: string
  isLoading?: boolean
  onChange?: () => void
}

export function SelectMultiple<T extends Option>({
  field,
  options,
  label,
  title,
  modalTitle,
  gropedByKey,
  isLoading,
  onChange,
}: AsyncSelectMultipleProps<T>) {
  const [isShow, openDrawer, closeDrawer] = useDrawer()
  const { setValue, watch } = useFormContext()
  const [search, setSearch] = useState<string>('')
  const valuesForm: T[] = watch(field) || []
  const [selectedOptions, setSelectedOptions] = useState<T[]>(valuesForm || [])

  const handleDrawerClose = () => {
    setSearch('')
    closeDrawer()
  }

  const handleSelectedValueCheck = useCallback(
    (option: T) =>
      selectedOptions.some((selected) => selected.id === option.id),
    [selectedOptions],
  )

  const handleOnChange = useCallback(
    (option: T) => {
      if (handleSelectedValueCheck(option)) {
        setSelectedOptions((prevOptions) =>
          prevOptions.filter((value) => value.id !== option.id),
        )
      } else {
        setSelectedOptions((prevOptions) => [...prevOptions, option])
      }
    },
    [handleSelectedValueCheck],
  )

  const handleOnClear = () => {
    setSelectedOptions([])
    setValue(field, undefined)
    handleDrawerClose()
    onChange?.()
  }

  const handleOnApply = () => {
    setValue(field, selectedOptions)
    handleDrawerClose()
    onChange?.()
  }

  const handleInputChange = useCallback(
    (value?: string | null) => {
      setSearch(value || '')
    },
    [setSearch],
  )

  const filteredOptions = useMemo(
    () => filterOptions<T>(search, options),
    [options, search],
  )

  useEffect(() => {
    setSelectedOptions(valuesForm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow])

  const renderCheckbox = useCallback(
    (option: T) => (
      <Checkbox
        key={option.id}
        label={option.label}
        onChange={() => handleOnChange(option)}
        checked={handleSelectedValueCheck(option)}
      />
    ),
    [handleOnChange, handleSelectedValueCheck],
  )

  const optionsBlock = useMemo(() => {
    type MappedOptions = typeof gropedByKey extends undefined
      ? T[]
      : [string, T[]][]

    const mappedOptions = (
      gropedByKey?.length
        ? groupOptionsByKey<T>(gropedByKey, filteredOptions)
        : filteredOptions
    ) as MappedOptions

    return (
      <CheckBoxWrapper>
        {gropedByKey
          ? mappedOptions.map(([title, option]) => (
              <Fragment key={title}>
                <CheckboxTitle>{title}</CheckboxTitle>
                {option.map((option) => renderCheckbox(option))}
              </Fragment>
            ))
          : filteredOptions.map((option) => renderCheckbox(option))}
      </CheckBoxWrapper>
    )
  }, [filteredOptions, gropedByKey, renderCheckbox])

  return (
    <div>
      {title && <Title>{title}</Title>}

      <InputWrapper onClick={openDrawer}>
        <IonInput
          value={valuesForm.map(({ label }) => label).join(', ')}
          label={label}
          mode='md'
          readonly
          labelPlacement='floating'
          fill='outline'
        />
        <InputIcon icon={isShow ? chevronUpOutline : chevronDownOutline} />
      </InputWrapper>

      <DrawerComponent
        isShow={isShow}
        onCancel={handleDrawerClose}
        contentProps={{
          className: 'ion-content-scroll-host',
          sx: { height: '90vh' },
        }}
      >
        <DrawerWrapper>
          <Title>{modalTitle}</Title>

          <IonInput
            label='Поиск'
            mode='md'
            labelPlacement='floating'
            fill='outline'
            onIonInput={(e) => handleInputChange(e.detail.value)}
          />

          {isLoading && <SkeletonList count={10} skeletonHeight={56} />}

          {!isLoading && !filteredOptions.length && (
            <div>Ничего не найдено</div>
          )}

          {!isLoading && Boolean(filteredOptions?.length) && (
            <>
              {!search?.length && <CheckboxTitle>Результат</CheckboxTitle>}

              {optionsBlock}

              <ButtonsWrapper>
                <Button
                  fullWidth
                  variant='lightSteelBlueBorder'
                  onClick={handleOnClear}
                >
                  Сбросить
                </Button>
                <Button fullWidth variant='limeFlooded' onClick={handleOnApply}>
                  Применить
                </Button>
              </ButtonsWrapper>
            </>
          )}
        </DrawerWrapper>
      </DrawerComponent>
    </div>
  )
}
