import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'
import { WhiteBlock } from '~/shared/ui/Wrappers'

export const ItemWrapper = styled(WhiteBlock)`
  padding: 16px;
`

export const Title = styled('div')`
  font-size: 16px;
  min-width: 136px;
  color: ${myPalette.dark.main};
`

export const Value = styled('div')`
  font-size: 18px;
  font-weight: 500;
`
