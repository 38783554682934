import { BookingCarAttributes } from '~/shared/api'
import {
  fuelTypeEnumLabels,
  transmissionEnumLabels,
} from '~/shared/config/constants'
import { CalendarIcon } from '~/shared/ui/icons'
import { ChipWrapper, Chip } from './styled'

export type CharacteristicsProps = {
  carAttributes: BookingCarAttributes
}

export function Characteristics({ carAttributes }: CharacteristicsProps) {
  const rows = [
    { label: carAttributes.plateNumber },
    { label: 'График 7/0', icon: <CalendarIcon /> },
    { label: carAttributes.manufactureYear },
    {
      label: transmissionEnumLabels[carAttributes.transmission],
    },
    { label: fuelTypeEnumLabels[carAttributes.fuelType] },
  ]

  return (
    <ChipWrapper>
      {rows.map(({ label, icon }, index) => (
        <Chip
          key={index}
          avatar={icon ? <CalendarIcon /> : undefined}
          label={label}
          variant='outlined'
          size='small'
        />
      ))}
    </ChipWrapper>
  )
}
