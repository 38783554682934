import { LoadingButton as MuiLoadingButton } from '@mui/lab'
import { styled } from '@mui/material'
import { WhiteBlock } from '~/shared/ui/Wrappers'

export const SignButton = styled(MuiLoadingButton)`
  position: absolute;
  bottom: 24px;
  padding: 0;
  left: 12px;
  right: 12px;
`

export const FileButton = styled(MuiLoadingButton)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: auto;
  min-width: 126px;
  height: 72px;
`

export const Wrapper = styled(WhiteBlock)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const DescriptionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
