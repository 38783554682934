import { IonToast as IonicIonToast } from '@ionic/react'
import { styled } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'

export const IonToast = styled(IonicIonToast)`
  --max-width: 92%;
  --min-width: 92%;

  &::part(container) {
    padding: 5px;
    background: ${myPalette.white.main};
    border-radius: 8px;
  }

  &::part(header) {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  &::part(message) {
    font-size: 16px;
    margin-bottom: 4px;
  }

  &::part(button) {
    --button-color: ${myPalette.blue.main};
    width: 50%;
    margin-right: 26px;
    padding-top: 1px;
    font-size: 16px;
  }

  &::part(button cancel) {
    margin-left: 26px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: end;
  }
`
