import { IonChip as IonicIonChip } from '@ionic/react'
import { styled } from '@mui/material'

export const IonChip = styled(IonicIonChip)`
  margin: 0;
  position: absolute;
  top: 0;
  left: 16px;
  right: 16px;
`
