import EditNoteIcon from '@mui/icons-material/EditNote'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import { Typography } from '@mui/material'
import { useGate, useStore } from 'effector-react'
import { formatDateForUI } from '~/shared/lib/date'
import { Card, TitleWrapper } from '../styled'
import {
  Gate,
  $offer,
  fetchLatestPublicOfferFx,
  loadSignOffer,
  loadSignOfferFx,
} from './mode'
import { Button, ButtonsWrapper, LoadingButton, Skeleton } from './styled'

export function DocumentOffer() {
  useGate(Gate)

  const offer = useStore($offer)
  const isFetchOfferPending = useStore(fetchLatestPublicOfferFx.pending)
  const isLoadSignOfferPending = useStore(loadSignOfferFx.pending)
  const confirmedAt = offer?.getConfirmedAt()

  return (
    <Card data-testid='document-offer-block'>
      <TitleWrapper>
        <Typography fontSize={18} fontWeight={500}>
          Оферта
        </Typography>

        {confirmedAt && (
          <Typography
            fontSize={14}
            color='mutedText'
            data-testid='document-offer-date'
          >
            От {formatDateForUI(confirmedAt)}
          </Typography>
        )}
      </TitleWrapper>

      {!isFetchOfferPending && !offer && (
        <Typography>Документов нет</Typography>
      )}

      {isFetchOfferPending && <Skeleton />}

      {!isFetchOfferPending && offer && (
        <ButtonsWrapper data-testid='document-offer'>
          <LoadingButton
            loading={isLoadSignOfferPending}
            variant='lightSteelBlueBorder'
            size='small'
            onClick={() => loadSignOffer()}
            endIcon={<EditNoteIcon />}
          >
            Подпись
          </LoadingButton>

          <Button
            variant='limeFlooded'
            size='small'
            href={offer?.getDocumentFileLink() as string}
            target='_blank'
            endIcon={<FileOpenIcon />}
          >
            Оферта
          </Button>
        </ButtonsWrapper>
      )}
    </Card>
  )
}
