import { createDomain, sample, combine } from 'effector'
import { createGate } from 'effector-react'
import { entityBookingCarModel, FormValues } from '~/entities/BookingCar'
import { rentalContractModel } from '~/entities/RentalContract'
import { AxiosErrorType, RentalContactCreationRequest } from '~/shared/api'
import { backFx } from '~/shared/lib/history'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { notificationModalOpen } from '~/shared/lib/notificationModal'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('features.bookingCars.bookingCar')
export const Gate = createGate<{ id: UniqueId }>()

export const $id = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { id }) => id)

export const $bookingCar = combine(
  $id,
  entityBookingCarModel.$bookingCarsCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $id,
  filter: isString,
  target: entityBookingCarModel.requestFx,
})

export const rentalContactCreationFormSubmit = domain.createEvent<{
  formValues: FormValues
  closeModal: () => void
}>()

export const rentalContactCreationRequestFx = domain.createEffect<
  {
    bookingCarId: UniqueId
    closeModal: () => void
    formValues: FormValues
  },
  void,
  AxiosErrorType
>({
  async handler({
    bookingCarId,
    formValues: { workSchedule, dayOff },
    closeModal,
  }) {
    const rentalContactCreationRequest = new RentalContactCreationRequest({
      workSchedule: workSchedule.id,
      dayOff: dayOff?.id || dayOff,
    })

    if (isString(bookingCarId))
      rentalContactCreationRequest.setBookingCar(bookingCarId)

    await rentalContactCreationRequest.save()
    closeModal()
  },
})

sample({
  clock: rentalContactCreationFormSubmit,
  source: $id,
  filter: isString,
  fn: (bookingCarId, entries) => ({
    ...entries,
    bookingCarId,
  }),
  target: rentalContactCreationRequestFx,
})

const goToBookingCarsFx = domain.createEffect({
  handler() {
    backFx()
  },
})

sample({
  clock: rentalContactCreationRequestFx.doneData,
  fn() {
    return {
      title: 'Автомобиль успешно забронирован',
      description:
        'Договор появится в приложение в течение 10 минут. В течение 2 часов приезжайте в офис для подписания договора',
      variant: 'success' as const,
    }
  },
  target: [
    notificationModalOpen,
    rentalContractModel.getRentalContractSilentFx,
    goToBookingCarsFx,
  ],
})

sample({
  clock: rentalContactCreationRequestFx.failData,
  fn(e) {
    return {
      title: 'Не удалось забронировать автомобиль',
      description: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: notificationModalOpen,
})
