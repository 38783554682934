import { BalanceNav } from '~/widgets/blocks/BalanceNav'
import { RentalContract } from '~/features/RentalContract'
import { RentalContractSigningAlert } from '~/features/RentalContractSigning'
import { ListStory } from '~/features/Story'
import { GoToInspectionsButton } from '~/entities/Inspection'
import { CurrentBalance } from '~/entities/MainBalance'
import { PageContainer } from '~/shared/ui/PageContainer'
import { Wrapper } from './styled'

export function HomePage() {
  return (
    <PageContainer
      canMenu
      title='Апарк. Водитель'
      topContent={<RentalContract />}
      extraRight={<CurrentBalance />}
    >
      <Wrapper>
        <RentalContractSigningAlert />

        <BalanceNav />

        <GoToInspectionsButton />

        <ListStory />
      </Wrapper>
    </PageContainer>
  )
}
