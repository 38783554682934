import {
  IonContent as IonicIonContent,
  IonToolbar as IonicIonToolbar,
} from '@ionic/react'
import { styled, Box, Typography } from '@mui/material'
import { HEADER_HEIGHT, myPalette } from '~/shared/ui/theme'

export const IonContent = styled(IonicIonContent)`
  --background: ${myPalette.aliceBlue.main};
  --overscroll-behavior: none;
  --webkit-overscroll-behavior: none;
`

export const Content = styled(Box)`
  background: ${myPalette.aliceBlue.main};
  padding: 6px 16px 16px;
  flex: 1;
`

export const IonToolbar = styled(IonicIonToolbar)`
  --border-width: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-bottom: 0;
  --padding-top: 0px;
  --box-shadow: none;
`

export const WrapperHeader = styled(Box)`
  background: ${myPalette.aliceBlue.main};
  min-height: ${HEADER_HEIGHT};
  max-height: ${HEADER_HEIGHT};
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

export const Title = styled(Typography)`
  position: absolute;
  right: 0;
  text-align: center;
  width: 100%;
`
