import { styled, Typography, Button as MuiButton } from '@mui/material'
import { myPalette } from '~/shared/ui/theme'
import { WhiteBlock } from '~/shared/ui/Wrappers'

export const Wrapper = styled(WhiteBlock)`
  box-shadow: none;
  background: ${myPalette.midnightBlue['100']};
`

export const SubTitle = styled(Typography)`
  color: ${myPalette.dark.main};
  font-size: 12px;
  margin-bottom: 4px;
`

export const Title = styled(Typography)`
  color: ${myPalette.midnightBlue.main};
  font-size: 16px;
  font-weight: 500;
  position: relative;
  width: 100%;
  color: ${myPalette.white.main};
`

export const Button = styled(MuiButton)`
  color: ${myPalette.blue.main};
  font-size: 14px;
  font-weight: 400;
  padding: 4px;
  margin-top: 12px;
` as typeof MuiButton
