import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useGate, useStore, useStoreMap } from 'effector-react'
import { useState } from 'react'
import { $formMeta, $formMetaPending, Gate } from './mode'

type TinkoffPayProps = {
  amount: number
}

export function TinkoffPay({ amount }: TinkoffPayProps) {
  useGate(Gate)
  const isFormMetaLoading = useStore($formMetaPending)
  const formMeta = useStoreMap($formMeta, (formMeta) =>
    formMeta?.getAttributes(),
  )
  const [isSubmitting, setIsSubmitting] = useState<boolean>()

  function createTinkoffForm() {
    const form = document.createElement('form')
    form.name = 'payform-tinkoff'

    form.innerHTML = `
      <input type="hidden" name="terminalkey" value=${formMeta?.terminalId}>
      <input type="hidden" name="frame" value="false">
      <input type="hidden" name="language" value="ru">
      <input type="hidden" placeholder="Сумма заказа" name="amount" value=${amount} required>
      <input class="payform-tinkoff-row" type="hidden" name="DATA" value="Number=${formMeta?.customerId}">
    `

    return form
  }

  const handleTinkoffPay = () => {
    // В pay обязательно передается форма
    // https://www.tbank.ru/kassa/dev/widget/#section/Inicializaciya-platezha-cherez-platezhnyj-vidzhet/Ustanovka-vidzheta-bez-cheka

    try {
      setIsSubmitting(true)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.pay(createTinkoffForm())

      // Делаем обновление страницы так как повторное пополнение / переход на виджет не работает
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    } finally {
      setIsSubmitting(false)
    }
  }

  if (!isFormMetaLoading && !isFormMetaLoading && !formMeta) {
    return (
      <Typography fontSize='16px' textAlign='center' color='error'>
        Ошибка получения данных. Невозможно выполнить платеж!
      </Typography>
    )
  }

  return (
    <LoadingButton
      fullWidth
      loading={isFormMetaLoading || isSubmitting}
      variant='limeFlooded'
      size='large'
      onClick={handleTinkoffPay}
    >
      Подтвердить
    </LoadingButton>
  )
}
