import { PredefinedColors, ToastButton } from '@ionic/core'
import { createDomain } from 'effector'

const domain = createDomain('notifications')

export type DefaultNotification = {
  variant: PredefinedColors
  message: string
}

export type BigInfoNotification = {
  variant: 'bigInfo'
  title: string
  message: string
  actions?: () => void
  buttons?: ToastButton[]
}

export type Notification = DefaultNotification | BigInfoNotification

export type Snackbar = {
  id: string
} & Notification

export const snackbarEnqueued = domain.createEvent<Notification>()
export const snackbarClosed = domain.createEvent<string>()
export const allSnackbarsClosed = domain.createEvent()

export const $notifications = domain
  .createStore<Snackbar[]>([])
  .on(snackbarEnqueued, (state, payload) => [
    ...state,
    {
      id: String(new Date().getTime() + Math.random()),
      ...payload,
    },
  ])
  .on(snackbarClosed, (notifications, id) =>
    notifications.filter((notification) => notification.id !== id),
  )
  .on(allSnackbarsClosed, () => [])
